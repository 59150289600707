import React from "react";

const ActionButton = ({ title, icon = null, suffixIcon = null, ...props }) => {
    return (
        <button className="flex justify-between items-center gap-2 py-1.5 px-4 text-gray-50 text-sm font-semibold hover:bg-indigo-300 bg-indigo-500 rounded drop-shadow-md" {...props}>
            {icon} {title} {suffixIcon}
        </button>
    );
};

export { ActionButton };
