import React from "react";
import VAtable from "../components/info/VAtable";
import DAtable from "../components/info/DAtable";
import WBItable from "../components/info/WBItable";
import PAItable from "../components/info/PAItable";
import DataByMake from "../components/info/DataByMake";
const InfoPage = () => {
    return (
        <>
            <div className="flex-1 flex-col items-center">
                <div className="mt-8 mb-5 text-indigo-700 text-lg font-bold">Monthly Rate Scraped In Websites Include :</div>
                <WBItable />
                <div className="mt-12 mb-5 text-indigo-700 text-lg font-bold">Your Current Monthly Rate Adjusted To Include :</div>
                <PAItable />
                <div className="mt-12 mb-5 text-indigo-700 text-lg font-bold">Volumes available :</div>
                <VAtable />
                <div className="mt-12 mb-5 text-indigo-700 text-lg font-bold">Vehicles Available By Duration :</div>
                <DAtable />
                <div className="mt-12 mb-5 text-indigo-700 text-lg font-bold">Vehicles Available By Make :</div>
                <DataByMake />
            </div>
        </>
    );
};

export default InfoPage;
