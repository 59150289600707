import React, { useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import HighchartsComponent from "../HighchartsComponent";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { CustIDByScope, useBrandConfigurator } from "../../contexts/BrandConfiguratorProvider";
import MeasuresContext from "../../contexts/Measures";
import { medianByMake, weightedAverageByMake } from "../../utils/calculationUtils";
import * as Md from "../../md/full";
import FiltersContext from "../../contexts/Filters";
import { calculationModes } from "../../routes/BrandModelAnalysis";

const widgetId = "brand-column-chart";

const ColumnChartAnalysis = ({ calculationMode = "average", ...props }) => {
    const { filterSelectors } = useBrandConfigurator();
    const { selectedKpi } = useContext(MeasuresContext);
    // Group by make
    const [series, setSeries] = useState([]);
    const [status, setStatus] = useState(undefined);

    useEffect(() => {
        // Calculated weighted average by model
        setStatus("loading");
        setTimeout(() => {
            const seriesTmp = Object.entries(filterSelectors).reduce((acc, [make, modelsObject]) => {
                const weightedAvgMakePromo = calculationMode === "average" ? weightedAverageByMake(modelsObject, selectedKpi, calculationModes[calculationMode].calculationMethod, "promo") : medianByMake(modelsObject, selectedKpi, calculationModes[calculationMode].calculationMethod, "promo");
                const weightedAvgMakeConfig = calculationMode === "average" ? weightedAverageByMake(modelsObject, selectedKpi, calculationModes[calculationMode].calculationMethod, "config") : medianByMake(modelsObject, selectedKpi, calculationModes[calculationMode].calculationMethod, "config");

                if (weightedAvgMakePromo > 0 || weightedAvgMakeConfig > 0) {
                    acc = [
                        ...acc,
                        Object.assign({
                            name: make,
                            y: weightedAvgMakeConfig < weightedAvgMakePromo ? weightedAvgMakeConfig || weightedAvgMakePromo : weightedAvgMakePromo || weightedAvgMakeConfig,
                            promo: weightedAvgMakePromo,
                            config: weightedAvgMakeConfig,
                            color: "white",
                            borderColor: "#3a0ca3",
                            borderWidth: 2,
                        }),
                    ];
                }
                return acc;
            }, []);
            seriesTmp?.sort((a, b) => b.y - a.y);
            setSeries([...seriesTmp]);
            setStatus("complete");
        }, 100);

        return () => {
            setSeries([]);
            setStatus(undefined);
        };
    }, [filterSelectors, selectedKpi.index, calculationMode]);

    return status === "loading" ? (
        <LoadingComponent />
    ) : (
        <div className="flex flex-col gap-2">
            <ColumnChart id={widgetId} filters={props.filters} series={series} calculationMode={calculationMode} menuButtonPosition={"inside"} {...props} />
            {props.footnote && <span className="pl-3 pb-2 text-left text-xs font-medium">{props.footnote}</span>}
        </div>
    );
};

/**
 *
 * Widget used on the dashboard
 */
const ColumnChartAnalysisWidget = ({ calculationMode = "average", ...props }) => {
    const { seriesBy, selectedKpi } = useContext(MeasuresContext);
    const [series, setSeries] = useState([]);
    const { status, result } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CorrMake, Md.CorrModel, Md.CustId, Md.VersionStd],
            filters: props.filters,
        },
    });
    const { getFirstFilterValue } = useContext(FiltersContext);
    const countryId = getFirstFilterValue("filterCountryId");
    const channel1 = getFirstFilterValue("filterChannel1");
    const oemPromoCustIDs = CustIDByScope[`${countryId}-${channel1}`]?.promo;
    const oemConfigCustIDs = CustIDByScope[`${countryId}-${channel1}`]?.config;

    const makeModelObject = useMemo(() => {
        const slices = result?.data().slices().toArray();
        const mappedObj = slices?.reduce((acc, slice) => {
            const [make, model, custId, versionStd] = slice.sliceTitles();
            const versionObject = {
                label: versionStd,
                [custId]: slice.dataPoints().map((dp) => (dp.rawValue !== null ? Number(dp.rawValue) : null)),
            };
            if (!(make in acc)) {
                acc[make] = {
                    [model]: [versionObject],
                };
            } else {
                if (model in acc[make]) {
                    const index = acc[make][model].findIndex((obj) => obj.label === versionStd);
                    if (index !== -1) {
                        acc[make][model][index] = {
                            ...acc[make][model][index],
                            ...versionObject,
                        };
                    } else {
                        acc[make][model] = [...acc[make][model], versionObject];
                    }
                } else {
                    acc[make][model] = [versionObject];
                }
            }
            return acc;
        }, {});
        // Group the config and promo, by selecting only the min
        const res = mappedObj
            ? Object.entries(mappedObj).reduce((acc, [make, modelsObj]) => {
                  const modelObjCopy = Object.entries(modelsObj).reduce((accModel, [model, versionsArr]) => {
                      const versionArrCopy = versionsArr.map((versionObj) => ({
                          ...versionObj,
                          config: _.min(oemConfigCustIDs.map((custId) => (custId in versionObj ? versionObj[custId][selectedKpi.index] : null))) || null,
                          promo: _.min(oemPromoCustIDs.map((custId) => (custId in versionObj ? versionObj[custId][selectedKpi.index] : null))) || null,
                      }));
                      return {
                          ...accModel,
                          [model]: versionArrCopy,
                      };
                  }, {});
                  return {
                      ...acc,
                      [make]: modelObjCopy,
                  };
              }, {})
            : {};

        return res;
    }, [result, status, selectedKpi]);

    useEffect(() => {
        if (makeModelObject) {
            const seriesTmp = Object.entries(makeModelObject).reduce((acc, [make, modelsObject]) => {
                const weightedAvgMakePromo = weightedAverageByMake(modelsObject, selectedKpi, calculationModes[calculationMode].calculationMethod, "promo");
                const weightedAvgMakeConfig = weightedAverageByMake(modelsObject, selectedKpi, calculationModes[calculationMode].calculationMethod, "config");

                return [
                    ...acc,
                    Object.assign({
                        name: make,
                        y: weightedAvgMakeConfig < weightedAvgMakePromo ? weightedAvgMakeConfig || weightedAvgMakePromo : weightedAvgMakePromo || weightedAvgMakeConfig,
                        promo: weightedAvgMakePromo,
                        config: weightedAvgMakeConfig,
                        color: "white",
                        borderColor: "#3a0ca3",
                        borderWidth: 2,
                    }),
                ];
            }, []);
            seriesTmp?.sort((a, b) => b.y - a.y);
            seriesTmp && setSeries([...seriesTmp]);
        }
    }, [makeModelObject]);

    return status === "loading" || series.length <= 0 ? (
        <div className="flex flex-col items-center justify-center gap-2 min-h-[400px]">
            <div>
                <LoadingComponent />
            </div>
            <span>Loading...</span>
        </div>
    ) : (
        <ColumnChart id={widgetId} filters={props.filters} series={series} calculationMode={calculationMode} {...props} />
    );
};

const ColumnChart = ({ id, filters, series, ...props }) => (
    <HighchartsComponent
        widgetProps={{
            id,
            filters,
            section: "oem-views",
            calculationMode: props.calculationMode,
        }}
        options={{
            chart: {
                type: "column",
                height: 500,
                marginTop: 65,
                spacingBottom: 2,
            },
            plotOptions: {
                column: {
                    maxPointWidth: 60,
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true,
                    },
                },
                spline: {
                    lineWidth: 0,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                },
            },
            title: {
                text: props.title,
                align: "center",
                style: {
                    fontSize: "15px",
                    fontWeight: 600,
                    color: "#3a0ca3",
                },
            },
            subtitle: {
                text: props.subtitle,
                align: "center",
                style: {
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#506e96",
                },
            },
            xAxis: {
                categories: series.length > 0 ? series.map((serie) => serie.name) : null,
                // labels: labels || {
                //     padding: 3,
                //     reserveSpace: series.length > 0,
                //     style: {
                //         fontSize: pdfMode ? "8px" : "10px",
                //         fontWeight: 600,
                //         textOverflow: "none",
                //     },
                // },
                title: {
                    text: null,
                },
            },
            yAxis: {
                labels: {
                    enabled: false,
                },
                title: {
                    text: null,
                },
                min: 0,
                // max: series.length > 0 ?series[0].y * 1.1 : null,
                tickAmount: null,
                // plotLines: referenceLine && [
                //     {
                //         value: referenceLine.value,
                //         width: 2,
                //         zIndex: 10,
                //         color: "#FF0000",
                //         dashStyle: "shortdash",
                //     },
                // ],
            },
            legend: {
                borderColor: "#3a0ca3",
                padding: 12,
                symbolRadius: 0,
                symbolWidth: 0,
                squareSymbol: false,
                useHTML: true,
                labelFormatter: function () {
                    return this.name === "Cheapest"
                        ? `<div style="display:flex; gap:3px; align-items: center; margin: 4px 2px;"><div style="width:10px; height:10px; border: 2px solid #3a0ca3"></div> ${this.name}</div>`
                        : `<div style="display:flex; gap:3px; align-items: center; margin: 4px 2px;"><div style="width:10px; height:10px; border-radius: 20px; background-color: ${this.color};"></div> ${this.name}</div>`;
                },
            },
            series:
                series.length > 0
                    ? [
                          {
                              name: "Cheapest",
                              type: "column",
                              data: series,
                              // showInLegend: false,
                              enableMouseTracking: false,
                              legendColor: "#ff7d93",
                          },
                          {
                              name: "OEM promo",
                              type: "spline",
                              color: "#ff7d93",
                              showInLegend: series.filter((serie) => serie.promo !== null).length > 0,
                              data: series.map((serie) =>
                                  Object.assign({
                                      y: serie.promo,
                                      // color: BrandColors[serie.name]
                                  }),
                              ),
                          },
                          {
                              name: "OEM Config",
                              type: "spline",
                              color: "#3a0ca3",
                              showInLegend: series.filter((serie) => serie.config !== null).length > 0,
                              data: series.map((serie) =>
                                  Object.assign({
                                      y: serie.config,
                                      // color: BrandColors[serie.name]
                                  }),
                              ),
                              marker: {
                                  symbol: "circle",
                                  fillColor: "#3a0ca3",
                              },
                          },
                      ]
                    : null,
            lang: {
                noData: "No data available",
            },
            noData: {
                style: {
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "#595959",
                },
            },
        }}
        {...props}
    />
);

export { ColumnChartAnalysis, ColumnChartAnalysisWidget };
