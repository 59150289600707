import React, { useState, useContext, useEffect, useMemo } from "react";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { newAbsoluteDateFilter, newAttributeSort, newMeasureSort, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import moment from "moment";
import "./print.css";
import * as Md from "../../../md/full";
import { useBasketContext } from "../../../contexts/BasketProvider";
import UserContext from "../../../contexts/User";
import FiltersContext from "../../../contexts/Filters";
import MeasuresContext from "../../../contexts/Measures";
import { BasketBarChart } from "../BasketBarChart";
import { BasketLineChart } from "../BasketLineChart";
import { useAppContext } from "../../../contexts/AppContext";
import { BasketReference } from "../BasketReference";
import UpDownButton from "../../Buttons/UpDownButton";
import { newJsonPivot } from "../../../utils/jsonPivot";
import { colors } from "../../../constants";
import Selectedcust from "../../Layout/Selectedcust";
import BasketSynthesisChart from "./BasketSynthesisChart";
import { message } from "antd";
import BasketVersionMatch from "./BasketVersionMatch";
import { processTrendDataChanges } from "../../../utils/calculationUtils";
import { CarIcon } from "../../Icons/CarIcon";

const PdfHeader = ({ basketName, country, date, channel1, duration, kpi, adjdeposititle }) => (
    <div className="flex justify-between items-end pb-2 border-b border-indigo-700">
        <div className="flex-1 flex flex-col w-full items-stretch">
            <span className="leading-5 text-xl font-bold text-indigo-700">
                {country} - Basket {basketName} - {date}
            </span>
            <span className="font-semibold">
                Monthly {kpi} in {country !== "United Kingdom" ? "€" : "£"} {adjdeposititle ? "| " + adjdeposititle : ""} | {channel1} Channel | {duration} (Months/{country === "United Kingdom" ? "Miles" : "KM"})
            </span>
        </div>
        <img className="relative float-right z-10" alt="Experteye_Logo" src="images/experteye-logo.png" height={12.0} />
    </div>
);

const BasketSummary = ({ userPref = {}, kpi = {}, ...props }) => {
    const summaryLabels = {
        //@ts-ignore
        Maintenance: userPref?.flag_ser,
        //@ts-ignore
        Breakdown: userPref?.flag_bre,
        //@ts-ignore
        Insurance: userPref?.flag_ins,
        //@ts-ignore
        Tyres: userPref?.flag_tyr,
        //@ts-ignore
        "Financial loss": userPref?.flag_fin,
    };
    let summaryLabelsIterator = Object.entries(summaryLabels);
    return (
        <span className="italic text-gray-500 text-xs font-light">
            {/* @ts-ignore */}
            {`Monthly Rate Incl. VAT, LCV Rental Excl. VAT,  ${kpi?.name}, `}
            {/* kpi is Adj.Rental or lease factor */}
            {/* @ts-ignore */}
            {[1, 4].includes(kpi?.id) && summaryLabelsIterator.map(([key, value], index) => (value ? "Incl." : "Excl.") + " " + key + (index > summaryLabelsIterator?.length - 1 ? ", " : " "))}
        </span>
    );
};

function BasketViz({ basket, printMode, custIds = [], selectedKpi, filterCountryId, filterMthCode, filterChannel1, filterDuration, printStatus, setIsLoading, adjdeposititle }) {
    const { displayMode, currentFilter, filterPeriodFrequency } = useContext(FiltersContext);
    const userPreferenceContext = useContext(UserContext);
    const { user, defaultUserPref } = useContext(UserContext);
    const { seriesBy } = useContext(MeasuresContext);
    const [versionStdFilter, setVersionStdFilter] = useState(newPositiveAttributeFilter(Md.VersionStd, [basket.basketFilters.filter((elem) => elem.type === "version_std").map((elem) => elem.value)]));
    const [colorSeries, setColorSeries] = useState({});
    const [colorVSeries, setColorVSeries] = useState({});
    const { getCountryByCode, getColorByCustId, getCustlabelByCustId, showListPrices: showListPricesContext, dateRange } = useAppContext();
    const { updateBasketPrintStatus, editBasket } = useBasketContext();
    // Data used to render barcharts
    const [monthData, setMthData] = useState([]);
    const [monthVData, setMthVData] = useState([]);
    const [showVersionMatch, setShowVersionMatch] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState();
    const overview = React.createRef();
    const [showGraph, setShowGraph] = useState(user?.basket_sections?.Basket_Overview);
    const [scale, setScale] = useState({
        maxValue: undefined,
    });

    // Data used to render trend charts
    const [trendData, setTrendData] = useState({});
    const [trendDataV, setTrendDataV] = useState({});
    const [listPrices, setListPrices] = useState([]);
    const [trendCategoryTitles, setTrendCategoryTitles] = useState([]);
    const [basketReference, setBasketReference] = useState({
        customer: undefined,
        versionStd: undefined,
        value: undefined,
    });

    const [readyVizCounter, setReadyVizCounter] = useState(0);
    const [showBarFigures, setShowBarFigures] = useState(userPreferenceContext?.user?.basket_sections?.Basket_BarChart);
    const [showTrendCharts, setShowTrendCharts] = useState(userPreferenceContext?.user?.basket_sections?.Basket_Trends);
    // const incrementReadyVizCounter = () => {
    //     setReadyVizCounter(readyVizCounter + 1);
    // };
    const versionSlots = basket.basketFilters.filter((elem) => elem.type === "version_std").map((elem) => elem.value);
    const custSlots = Object.entries(userPreferenceContext.defaultUserPref)
        .filter(([key, val]) => key.includes("customer_slot") && val !== null)
        .map(([key, val]) => val);
    const custIdFilters = newPositiveAttributeFilter(Md.CustId, custSlots);
    const formattedDateRange = dateRange.map((dt) => dt.format("YYYY-MM-DD"));
    const basketTrendFilters = [
        versionStdFilter,
        filterChannel1,
        filterCountryId,
        filterDuration,
        custIdFilters,
        formattedDateRange.length === 2 ? newAbsoluteDateFilter(Md.DateDatasets.DateOfScraping.ref, formattedDateRange[0], formattedDateRange[1]) : null,
        !Boolean(defaultUserPref?.fl_weekly) ? newPositiveAttributeFilter(Md.Latestmchar, ["1"]) : null,
    ];

    const basketSpotFilters = [versionStdFilter, filterChannel1, filterCountryId, filterDuration, custIdFilters, filterMthCode, filterPeriodFrequency];

    const { result, status } = useExecutionDataView(
        {
            execution: {
                seriesBy: seriesBy,
                slicesBy: [Md.CustId, Md.VersionStd],
                filters: basketSpotFilters,
                sortBy: [newMeasureSort(seriesBy[selectedKpi.index]), newAttributeSort(Md.CustId)],
            },
        },
        [showListPricesContext],
    );

    // Reference line
    const { result: referenceResult, status: referenceStatus } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.VersionStd, Md.CustId],
            filters: [newPositiveAttributeFilter(Md.CustId, [basketReference?.customer]), newPositiveAttributeFilter(Md.VersionStd, [basketReference?.versionStd]), versionStdFilter, filterChannel1, filterCountryId, filterDuration, custIdFilters, filterMthCode],
        },
    });

    const { result: trendResult, status: trendStatus } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CustId, Md.DateDatasets.DateOfScraping.Date.YyyyMmDd, Md.VersionStd],
            filters: basketTrendFilters,
        },
    });

    // const measures = [24, 23].includes(selectedKpi.index) ? seriesBy : [seriesBy[selectedKpi.index]];
    var monthDate = moment(filterMthCode.positiveAttributeFilter.in?.values[0], "YYYY MM").format("MMMM YY");
    const country = filterCountryId.positiveAttributeFilter.in?.values[0];
    const countryLabel = getCountryByCode(country);

    useEffect(() => {
        setReadyVizCounter(0);
    }, []);

    useEffect(() => {
        if (readyVizCounter === custIds?.length * 2) {
            updateBasketPrintStatus(basket);
        }
    }, [readyVizCounter]);

    useEffect(() => {
        if (status !== "loading" && referenceStatus !== "loading" && trendStatus !== "loading") {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [status, referenceStatus, trendStatus, setIsLoading]);

    useEffect(() => {
        // if the basket ref never changed, then attached to the reference_line from the db or the default basket filter
        if (basket !== undefined && Object.values(basketReference).filter((val) => val !== undefined).length === 0) {
            const referenceLine = basket.reference_line || basketReference;
            if (referenceLine instanceof Object) {
                setBasketReference({
                    ...referenceLine,
                });
            } else {
                let defaultReference = basket.basketFilters.find((elem) => elem.reference === 1);
                setBasketReference({
                    ...basketReference,
                    versionStd: defaultReference?.value,
                });
            }
            let versionStdFilters = basket.basketFilters.filter((elem) => elem.type === "version_std").map((elem) => elem.value);
            // setVehiclesColors(vehiclesColors);
            let vehicleStdAttributeFilter = newPositiveAttributeFilter(Md.VersionStd, versionStdFilters);
            setVersionStdFilter(vehicleStdAttributeFilter);
        }
    }, [basket]);

    useEffect(() => {
        if (result) {
            const data = result.data().series().firstForMeasure(seriesBy[selectedKpi.index]);
            const dataPoints = data?.dataPoints().map((dp) =>
                Object.freeze({
                    value: Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue)),
                    custId: dp.sliceDesc.sliceTitles()[0],
                    versionStd: dp.sliceDesc.sliceTitles()[1],
                }),
            );
            const lpdata = result
                ?.data()
                .series()
                .toArray()
                .find((serie) => serie.measureTitle() === seriesBy[1].measure.localIdentifier)
                ?.dataPoints()
                .map((dp) =>
                    Object.freeze({
                        value: Math.round(Number(dp.rawValue)),
                        custId: dp.sliceDesc.sliceTitles()[0],
                        versionStd: dp.sliceDesc.sliceTitles()[1],
                    }),
                );
            if (lpdata) {
                let parsedData = newJsonPivot(lpdata, { row: "versionStd", column: "custId", value: "value" });
                setListPrices(parsedData);
            }
            const parsedData = newJsonPivot(dataPoints, { row: "versionStd", column: "custId", value: "value" });
            const prepasedData = newJsonPivot(dataPoints, { row: "custId", column: "versionStd", value: "value" });
            const maxValue = parsedData.reduce((acc, nextElem) => {
                const elemMaxValue = Object.entries(nextElem)
                    .filter(([key, val]) => custSlots.includes(key))
                    .reduce((maxValue, [key, nextValue]) => {
                        return maxValue > nextValue ? maxValue : nextValue;
                    }, 0);
                return acc > elemMaxValue ? acc : elemMaxValue;
            }, 0);
            setScale({
                maxValue,
            });
            setMthData(prepasedData);
            setMthVData(parsedData);
        }
        return () => {
            setMthData([]);
            setMthVData([]);
        };
    }, [result, selectedKpi]);

    useEffect(() => {
        const dataSerie = trendResult?.data().series().firstForMeasure(seriesBy[selectedKpi.index]);
        const slices = trendResult?.data().slices().toArray();
        if (dataSerie && slices) {
            const scrapingDates = slices.reduce((acc, slice) => {
                const scrapingDate = new Date(slice.sliceTitles()[1]).getTime();
                !acc.includes(scrapingDate) && acc.push(scrapingDate);
                return acc;
            }, []);
            scrapingDates.sort();
            let trendColorCopy = colors.slice();
            const serieColors = slices.reduce((acc, slice, index) => {
                let attrValue = displayMode.attributes.reduce((acc, attr, index) => acc + " " + slice.sliceTitles()[index + 2], "");
                if (!(attrValue in acc)) {
                    acc[attrValue] = trendColorCopy.shift();
                }
                // !acc.includes(attrValue) && acc.push(Object.assign());
                return acc;
            }, {});
            const serieVColors = slices.reduce((acc, slice, index) => {
                let attrValue = displayMode.attributes.reduce((acc, attr, index) => acc + "" + slice.sliceTitles()[index], "");
                if (!(attrValue in acc)) {
                    acc[attrValue] = trendColorCopy.shift();
                }
                // !acc.includes(attrValue) && acc.push(Object.assign());
                return acc;
            }, {});
            const trendData = dataSerie.dataPoints().reduce((acc, dp) => {
                const sliceTitles = dp.sliceDesc.sliceTitles();
                const custValue = sliceTitles[0];
                const serieTitle = displayMode.attributes.reduce((acc, attr, index) => acc + " " + sliceTitles[index + 2], "");
                const scrapeDate = new Date(sliceTitles[1]).getTime();
                const dataPoint = Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue));
                if (custValue in acc) {
                    let serieElem = acc[custValue].find((item) => item.name === serieTitle);
                    if (serieElem) {
                        const mthIndex = scrapingDates.findIndex((item) => item === scrapeDate);
                        serieElem.data[mthIndex] = [scrapeDate, dataPoint];
                    } else {
                        let dataValues = Array(scrapingDates?.length).fill([scrapeDate, null]);
                        const mthIndex = scrapingDates.findIndex((item) => item === scrapeDate);
                        dataValues[mthIndex] = [scrapeDate, dataPoint];
                        acc[custValue].push({
                            name: serieTitle,
                            data: dataValues,
                            color: serieColors[serieTitle],
                        });
                    }
                } else {
                    let dataValues = Array(scrapingDates?.length).fill([scrapeDate, null]);
                    const mthIndex = scrapingDates.findIndex((item) => item === scrapeDate);
                    dataValues[mthIndex] = [scrapeDate, dataPoint];
                    acc[custValue] = [
                        {
                            name: serieTitle,
                            data: dataValues,
                            color: serieColors[serieTitle],
                        },
                    ];
                }
                return acc;
            }, {});

            const TrendDataV = dataSerie.dataPoints().reduce((acc, dp) => {
                const sliceTitles = dp.sliceDesc.sliceTitles();
                const versionStd = sliceTitles[2];
                const custSerieTitle = getCustlabelByCustId(sliceTitles[0]);
                const scrapingDate = new Date(sliceTitles[1]).getTime();
                const dataPoint = Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue));
                if (versionStd in acc) {
                    let serieElem = acc[versionStd].find((item) => item.name === custSerieTitle);
                    if (serieElem) {
                        const mthIndex = scrapingDates.findIndex((item) => item === scrapingDate);
                        serieElem.data[mthIndex] = [scrapingDate, dataPoint];
                    } else {
                        let dataValues = Array(scrapingDates?.length).fill([scrapingDate, null]);
                        const mthIndex = scrapingDates.findIndex((item) => item === scrapingDate);
                        dataValues[mthIndex] = [scrapingDate, dataPoint];
                        acc[versionStd].push({
                            name: custSerieTitle,
                            data: dataValues,
                            color: getColorByCustId(sliceTitles[0]),
                        });
                    }
                } else {
                    let dataValues = Array(scrapingDates?.length).fill([scrapingDate, null]);
                    const mthIndex = scrapingDates.findIndex((item) => item === scrapingDate);
                    dataValues[mthIndex] = [scrapingDate, dataPoint];
                    acc[versionStd] = [
                        {
                            name: custSerieTitle,
                            data: dataValues,
                            color: getColorByCustId(custSerieTitle),
                        },
                    ];
                }
                return acc;
            }, {});

            setTrendCategoryTitles(scrapingDates);
            setTrendData(trendData);
            setTrendDataV(TrendDataV);
            setColorSeries(serieColors);
            setColorVSeries(serieVColors);
        }
    }, [trendResult, selectedKpi]);

    const minMaxValues = useMemo(() => {
        return Object.values(trendData).reduce(
            (acc, nextElem) => {
                const elemMaxValue = nextElem.reduce((acc, nextElem) => {
                    const serieMaxValue = nextElem.data.reduce((acc, nextElem) => {
                        return acc > nextElem[1] ? acc : nextElem[1];
                    }, 0);
                    return acc > serieMaxValue ? acc : serieMaxValue;
                }, 0);
                const elemMinValue = nextElem.reduce((acc, nextElem) => {
                    const serieMinValue = nextElem.data
                        .filter((item) => item !== null && item[1] !== null)
                        .reduce((acc, nextElem) => {
                            return acc < nextElem[1] ? acc : nextElem[1];
                        }, Infinity);
                    return acc < serieMinValue ? acc : serieMinValue;
                }, Infinity);
                return {
                    maxValue: acc.maxValue > elemMaxValue ? acc.maxValue : elemMaxValue,
                    minValue: acc.minValue < elemMinValue ? acc.minValue : elemMinValue,
                };
            },
            { maxValue: 0, minValue: Infinity },
        );
    }, [trendData, selectedKpi]);

    useEffect(() => {
        const dataSerie = referenceResult?.data().series().firstForMeasure(seriesBy[selectedKpi.index]);
        if (dataSerie) {
            const referenceValue = dataSerie.dataPoints().map((dp) => (selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue))))[0];
            setBasketReference({
                ...basketReference,
                value: referenceValue,
            });
        }
    }, [referenceResult, selectedKpi]);

    useEffect(() => {
        // if print mode, open figures
        if (printStatus) {
            !showBarFigures && setShowBarFigures(!showBarFigures);
            !showTrendCharts && setShowTrendCharts(!showTrendCharts);
            !showGraph && setShowGraph(!showGraph);
        }
        // if not print mode, set figures to user preference
        if (!printStatus) {
            showBarFigures !== userPreferenceContext?.user?.basket_sections?.Basket_BarChart && setShowBarFigures(userPreferenceContext?.user?.basket_sections?.Basket_BarChart);
            showTrendCharts !== userPreferenceContext?.user?.basket_sections?.Basket_Trends && setShowTrendCharts(userPreferenceContext?.user?.basket_sections?.Basket_Trends);
            showGraph !== userPreferenceContext?.user?.basket_sections?.Basket_Overview && setShowGraph(userPreferenceContext?.user?.basket_sections?.Basket_Overview);
        }
    }, [printStatus]);

    let valableSlots = custSlots.filter((elem) => monthData.find((item) => item.custId === elem));
    let basketSlotsPages = [];
    let basketTSlotsPages = [];
    for (let i = 0; i < valableSlots?.length; i += 4) {
        let newArr = valableSlots.slice(i, i + 4);
        basketSlotsPages.push(newArr);
    }
    for (let i = 0; i < valableSlots?.length; i += 2) {
        let newArr = valableSlots.slice(i, i + 2);
        basketTSlotsPages.push(newArr);
    }
    let valableVSlots = versionSlots.filter((elem) => monthVData.find((item) => item.versionStd === elem));
    let basketVSlotsPages = [];
    let basketTVSlotsPages = [];
    for (let i = 0; i < valableVSlots?.length; i += 4) {
        let newArr = valableVSlots.slice(i, i + 4);
        basketVSlotsPages.push(newArr);
    }
    for (let i = 0; i < valableVSlots?.length; i += 2) {
        let newArr = valableVSlots.slice(i, i + 2);
        basketTVSlotsPages.push(newArr);
    }

    function onSelectBasketReference(refObj) {
        const { customer, versionStd } = refObj;
        if (basket.type === "user") editBasket(basket.id, { reference_line: { customer, versionStd } });
        setBasketReference({ ...refObj });
    }

    const missingVehicleSlots = versionSlots.filter((elem) => !valableVSlots.includes(elem));

    return (
        <div className={!printStatus && "w-full flex flex-col items-stretch gap-4"}>
            {printMode !== "trends" && (
                <div className="break-after-page">
                    {!printStatus && (
                        <>
                            <div className="pt-2">
                                <span className="grow leading-5 text-xl font-bold text-indigo-700">Basket {basket.name}</span>
                            </div>
                            <div>
                                <div className="flex items-center gap-2 justify-between">
                                    <Selectedcust />
                                    {
                                        //@ts-ignore
                                        <BasketReference reference={basketReference} custIds={custIds} vehiclesStd={versionStdFilter.positiveAttributeFilter.in?.values} onSelect={onSelectBasketReference} />
                                    }
                                </div>
                                {!printStatus && missingVehicleSlots.length > 0 && (
                                    <div className="flex flex-row gap-4 items-start text-xs">
                                        <span className="font-medium text-indigo-700 text-sm mb-2 w-fit whitespace-nowrap">Missing vehicles:</span>
                                        <div className="grid grid-cols-3 items-center gap-2 pb-2">
                                            {missingVehicleSlots.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className="flex items-center border border-gray-400 rounded-md px-2 py-1 col-span-1 text-gray-400"
                                                    // onClick={() => {
                                                    //     const vehicle = basket.basketFilters.find(({ type, value }) => value === item && type === "version_std");
                                                    //     if (vehicle.make && vehicle.model && vehicle.energy) {
                                                    //         setSelectedVehicle(vehicle);
                                                    //         setShowVersionMatch(true);
                                                    //     } else {
                                                    //         message.error({
                                                    //             content: "Operation could not be implemented on this vehicle !",
                                                    //             style: {
                                                    //                 top: "80px",
                                                    //                 right: "20px",
                                                    //                 position: "absolute",
                                                    //             },
                                                    //             duration: 2,
                                                    //         });
                                                    //     }
                                                    // }}
                                                >
                                                    <CarIcon className="w-4 h-4 text-secondary-300 mr-1" />
                                                    <span className="font-bold text-[10px]">{item}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    <div className="pt-4">
                        {!printStatus && (
                            <div className="p-3 rounded-md flex justify-between items-stretch cursor-pointer hover:bg-gray-200" onClick={() => setShowGraph(!showGraph)}>
                                <span className="font-semibold text-lg text-steel">Overview</span>
                                <UpDownButton visibilityState={showGraph} />
                            </div>
                        )}
                        {showGraph && printMode !== "bars" && (
                            <div className={printStatus && "break-after-page"}>
                                {printStatus && (
                                    <PdfHeader adjdeposititle={adjdeposititle} country={countryLabel} channel1={filterChannel1.positiveAttributeFilter.in?.values[0]} duration={filterDuration.positiveAttributeFilter.in?.values[0]} kpi={selectedKpi.name} basketName={basket.name} date={monthDate} />
                                )}
                                <div
                                    style={{
                                        display: printStatus ? "flex" : "block",
                                        justifyContent: printStatus ? "center" : "initial",
                                        alignItems: printStatus ? "center" : "initial",
                                        height: printStatus ? (printMode === "Overview" ? "600px" : "800px") : "auto",
                                    }}
                                >
                                    <div className={`${printStatus ? "mt-4 border-2 border-gray-300 rounded" : ""} min-w-full`} ref={overview}>
                                        <BasketSynthesisChart
                                            printStatus={printStatus}
                                            data={monthVData}
                                            listPrices={listPrices}
                                            filters={[versionStdFilter, filterChannel1, filterCountryId, filterDuration, custIdFilters, filterMthCode]}
                                            basketReference={basketReference}
                                            isLoading={status === "loading"}
                                            title={
                                                printStatus
                                                    ? "Overview"
                                                    : `${basket.name} Monthly rate by player | ${countryLabel} | ${filterChannel1.positiveAttributeFilter.in?.values[0]} | ${filterDuration.positiveAttributeFilter.in?.values[0]} ${adjdeposititle ? " | " + adjdeposititle : ""} | ${selectedKpi.name}`
                                            }
                                            kpiSettings={userPreferenceContext.kpiSettings}
                                            menuButtonPosition="inside"
                                            width={printStatus ? (printMode === "Overview" ? 1024 : 1200) : overview?.current?.offsetWidth}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {!printStatus && (
                        <>
                            <div className="p-3 mt-4 rounded-md flex justify-between items-stretch cursor-pointer hover:bg-gray-200" onClick={() => setShowBarFigures(!showBarFigures)}>
                                <span className="font-semibold text-lg text-steel">Monthly rate by {displayMode.title}</span>
                                <UpDownButton visibilityState={showBarFigures} />
                            </div>
                        </>
                    )}
                    {showBarFigures &&
                        printMode !== "Overview" &&
                        (status === "loading" ? (
                            <LoadingComponent />
                        ) : displayMode.key === "player" ? (
                            basketSlotsPages.map((custSlots, index) => (
                                <div className={"break-after-page " + (basketSlotsPages?.length !== 1 ? "mt-1" : "")}>
                                    {printStatus && (
                                        <PdfHeader
                                            adjdeposititle={adjdeposititle}
                                            country={countryLabel}
                                            channel1={filterChannel1.positiveAttributeFilter.in?.values[0]}
                                            duration={filterDuration.positiveAttributeFilter.in?.values[0]}
                                            kpi={selectedKpi.name}
                                            basketName={basket.name}
                                            date={monthDate}
                                        />
                                    )}
                                    {
                                        <div className={printStatus ? "grid py-4 gap-4 grid-cols-basketPdfGrid" : "grid grid-cols-2 py-4 gap-4"}>
                                            {custSlots.map((custIndex, index) => {
                                                let player = monthData?.find((elem) => elem.custId === custIndex);
                                                let playerkeys = player && Object.keys(player).filter((key) => key !== "custId");
                                                let barChartData = playerkeys
                                                    ?.map((key) => {
                                                        return {
                                                            title: key,
                                                            dataPoint: player[key],
                                                        };
                                                    })
                                                    .filter((elem) => elem.dataPoint !== null);
                                                barChartData?.sort((a, b) => b.dataPoint - a.dataPoint);
                                                let filterCustId = newPositiveAttributeFilter(Md.CustId, [custIndex]);
                                                return (
                                                    <BasketBarChart
                                                        kpiSettings={userPreferenceContext.kpiSettings}
                                                        displayMode={displayMode}
                                                        filters={[versionStdFilter, filterChannel1, filterCountryId, filterDuration, filterCustId, filterMthCode]}
                                                        key={`${custIndex}-bar`}
                                                        custIndex={custIndex}
                                                        title={
                                                            printStatus
                                                                ? getCustlabelByCustId(custIndex)
                                                                : country === "GB"
                                                                ? `${getCustlabelByCustId(custIndex)} - ${countryLabel} - ${filterChannel1.positiveAttributeFilter.in?.values[0]} - ${filterDuration?.positiveAttributeFilter?.in?.values[0]}
                                                                ${adjdeposititle ? adjdeposititle + "-" : ""} ${selectedKpi.name}`
                                                                : `${getCustlabelByCustId(custIndex)} - ${countryLabel} - ${filterChannel1.positiveAttributeFilter.in?.values[0]} - ${filterDuration?.positiveAttributeFilter?.in?.values[0]} - ${selectedKpi.name}`
                                                        }
                                                        subtitle={!printStatus ? basket.name : null}
                                                        // chartData={props.basket.loadVizData("bar", custIndex)}
                                                        // onComplete={(data) => {
                                                        //     props.basket.storeVizData("bar", custIndex, data);
                                                        // }}
                                                        reference={basketReference}
                                                        data={barChartData}
                                                        chartProps={{
                                                            barWidth: printStatus ? 20 : 25,
                                                            width: printStatus ? 580 : null,
                                                            height: printStatus ? 350 : 450,
                                                            pdfMode: printStatus,
                                                            maxValue: scale.maxValue,
                                                            marginLeft: barChartData?.length > 0 ? 230 : null,
                                                            screenshotName: `basket-${basket.name}-spot-figure-${custIndex}`,
                                                            menuButtonPosition: "inside",
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    }
                                    {printStatus && basketReference?.customer && basketReference?.versionStd && (
                                        <div className="mt-2">
                                            <span className="font-semibold text-sm text-steel">
                                                Reference: {getCustlabelByCustId(basketReference.customer)} - {basketReference.versionStd}
                                            </span>
                                        </div>
                                    )}
                                    {printStatus && (
                                        <div className="fixed bottom-0">
                                            <BasketSummary userPref={userPreferenceContext.kpiSettings} kpi={selectedKpi} />
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            basketVSlotsPages.map((versionSlots, index) => (
                                <div className={"break-after-page " + (basketSlotsPages?.length !== 1 ? "mt-1" : "")}>
                                    {printStatus && (
                                        <PdfHeader
                                            adjdeposititle={adjdeposititle}
                                            country={countryLabel}
                                            channel1={filterChannel1.positiveAttributeFilter.in?.values[0]}
                                            duration={filterDuration.positiveAttributeFilter.in?.values[0]}
                                            kpi={selectedKpi.name}
                                            basketName={basket.name}
                                            date={monthDate}
                                        />
                                    )}
                                    {
                                        <div className={printStatus ? "grid py-4 gap-4 grid-cols-basketPdfGrid" : "grid grid-cols-2 py-4 gap-4"}>
                                            {versionSlots.map((version_std, index) => {
                                                let version = monthVData?.find((elem) => elem.versionStd === version_std);
                                                let versionkeys = version && Object.keys(version).filter((key) => key !== "versionStd");
                                                let barChartData = versionkeys
                                                    ?.map((key) => {
                                                        return {
                                                            title: key,
                                                            dataPoint: version[key],
                                                        };
                                                    })
                                                    .filter((elem) => elem.dataPoint !== null);
                                                barChartData?.sort((a, b) => b.dataPoint - a.dataPoint);
                                                let filterVersion = newPositiveAttributeFilter(Md.VersionStd, [version_std]);
                                                return (
                                                    <BasketBarChart
                                                        kpiSettings={userPreferenceContext.kpiSettings}
                                                        filters={[filterVersion, filterChannel1, filterCountryId, filterDuration, custIdFilters, filterMthCode]}
                                                        displayMode={displayMode}
                                                        key={`${version_std}-bar`}
                                                        custIndex={version_std}
                                                        title={
                                                            printStatus
                                                                ? version_std
                                                                : `${version_std}
                                            - ${countryLabel}
                                            - ${filterChannel1.positiveAttributeFilter.in?.values[0]} - ${filterDuration?.positiveAttributeFilter?.in?.values[0]} - ${adjdeposititle ? adjdeposititle + "-" : ""} ${selectedKpi.name}`
                                                        }
                                                        subtitle={!printStatus ? basket.name : null}
                                                        // chartData={props.basket.loadVizData("bar", custIndex)}
                                                        // onComplete={(data) => {
                                                        //     props.basket.storeVizData("bar", custIndex, data);
                                                        // }}
                                                        reference={basketReference}
                                                        data={barChartData}
                                                        chartProps={{
                                                            barWidth: printStatus ? 20 : 25,
                                                            width: printStatus ? 580 : null,
                                                            height: printStatus ? 350 : 450,
                                                            pdfMode: printStatus,
                                                            maxValue: scale.maxValue,
                                                            marginLeft: barChartData?.length > 0 ? 150 : null,
                                                            screenshotName: `basket-${basket.name}-spot-figure-${version_std}`,
                                                            menuButtonPosition: "inside",
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    }
                                    {printStatus && basketReference?.customer && basketReference?.versionStd && (
                                        <div className="mt-2">
                                            <span className="font-semibold text-sm text-steel">
                                                Reference: {getCustlabelByCustId(basketReference.customer)} - {basketReference.versionStd}
                                            </span>
                                        </div>
                                    )}
                                    {printStatus && (
                                        <div className="fixed bottom-0">
                                            <BasketSummary userPref={userPreferenceContext.kpiSettings} kpi={selectedKpi} />
                                        </div>
                                    )}
                                </div>
                            ))
                        ))}
                </div>
            )}
            {printMode !== "bars" && printMode !== "Overview" && (
                <div className="break-after-page">
                    {!printStatus && (
                        <div className="p-3 rounded-md flex justify-between items-stretch cursor-pointer hover:bg-gray-200" onClick={() => setShowTrendCharts(!showTrendCharts)}>
                            <span className="font-semibold text-lg text-steel">Monthly rate trends by {displayMode.title}</span>
                            <UpDownButton visibilityState={showTrendCharts} />
                        </div>
                    )}
                    {showTrendCharts &&
                        (referenceStatus === "loading" ? (
                            <div className="my-2">
                                <LoadingComponent />
                            </div>
                        ) : displayMode.key === "player" ? (
                            basketTSlotsPages.map((custSlots, index) => (
                                <div className={"break-after-page " + (basketTSlotsPages?.length !== 1 ? "mt-1" : "")}>
                                    {printStatus && (
                                        <PdfHeader
                                            adjdeposititle={adjdeposititle}
                                            country={countryLabel}
                                            channel1={filterChannel1.positiveAttributeFilter.in?.values[0]}
                                            duration={filterDuration.positiveAttributeFilter.in?.values[0]}
                                            kpi={selectedKpi.name}
                                            basketName={basket.name}
                                            date={monthDate}
                                        />
                                    )}
                                    {
                                        <div className={printStatus ? "grid py-4 gap-4" : "flex flex-col gap-4 items-stretch"}>
                                            {custSlots.map((custIndex, index) => {
                                                let filterCustId = newPositiveAttributeFilter(Md.CustId, [custIndex]);

                                                return (
                                                    <BasketLineChart
                                                        changesByMonth={processTrendDataChanges(trendResult, "versionStd", getCustlabelByCustId, seriesBy)}
                                                        kpiSettings={userPreferenceContext.kpiSettings}
                                                        // @ts-ignore
                                                        filters={basketTrendFilters.map((obj) => (obj?.positiveAttributeFilter?.displayForm.identifier === Md.CustId.attribute.displayForm.identifier ? filterCustId : obj))}
                                                        displayMode={displayMode}
                                                        custIndex={custIndex}
                                                        minValue={minMaxValues?.minValue * 0.9 || undefined}
                                                        maxValue={minMaxValues?.maxValue * 1.1 || undefined}
                                                        key={`${custIndex}-line`}
                                                        data={trendData[custIndex]}
                                                        categories={trendCategoryTitles}
                                                        title={
                                                            printStatus
                                                                ? getCustlabelByCustId(custIndex)
                                                                : `${getCustlabelByCustId(custIndex)}
                                                                - ${countryLabel}
                                                                - ${filterChannel1.positiveAttributeFilter.in?.values[0]} - ${filterDuration.positiveAttributeFilter.in?.values[0]} - ${adjdeposititle ? adjdeposititle + "-" : ""} ${selectedKpi.name}`
                                                        }
                                                        subtitle={!printStatus ? basket.name : null}
                                                        height={printStatus ? 300 : 530}
                                                        width={printStatus ? 1200 : null}
                                                        chartProps={{
                                                            pdfMode: printStatus,
                                                            screenshotName: `basket-${basket.name}-trends-${custIndex}`,
                                                            enableLegend: !printStatus,
                                                            marginBottom: printStatus ? 70 : trendData[custIndex]?.length > 6 ? 180 : 120,
                                                            menuButtonPosition: "inside",
                                                            // maxValue: maxTrendsValue * 1.1,
                                                            // minValue: minTrendsValue * 0.8,
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    }
                                    {printStatus && (
                                        <div className="legend h-min w-full grid grid-cols-2 gap-1">
                                            {Object.entries(colorSeries).map(([name, color]) => (
                                                <div className="flex gap-2 items-center">
                                                    <div
                                                        style={{
                                                            width: "15px",
                                                            // height: "10px",
                                                            borderBottom: `solid ${color}`,
                                                        }}
                                                    ></div>
                                                    <span className="text-xs font-normal">{name}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {printStatus && (
                                        <div className="fixed bottom-0">
                                            <BasketSummary userPref={userPreferenceContext.kpiSettings} kpi={selectedKpi} />
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            basketTVSlotsPages.map((versionSlots, index) => (
                                <div className={"break-after-page " + (basketTVSlotsPages?.length !== 1 ? "mt-1" : "")}>
                                    {printStatus && (
                                        <PdfHeader
                                            adjdeposititle={adjdeposititle}
                                            country={countryLabel}
                                            channel1={filterChannel1.positiveAttributeFilter.in?.values[0]}
                                            duration={filterDuration.positiveAttributeFilter.in?.values[0]}
                                            kpi={selectedKpi.name}
                                            basketName={basket.name}
                                            date={monthDate}
                                        />
                                    )}
                                    <div className={printStatus ? "grid py-4 gap-4" : "flex flex-col gap-4 items-stretch"}>
                                        {versionSlots.map((version, index) => {
                                            let filterVersion = newPositiveAttributeFilter(Md.VersionStd, [version]);
                                            return (
                                                <BasketLineChart
                                                    changesByMonth={processTrendDataChanges(trendResult, "custId", getCustlabelByCustId, seriesBy)}
                                                    kpiSettings={userPreferenceContext.kpiSettings}
                                                    minValue={minMaxValues?.minValue * 0.9 || undefined}
                                                    maxValue={minMaxValues?.maxValue * 1.1 || undefined}
                                                    //@ts-ignore
                                                    filters={basketTrendFilters.map((obj) => (obj?.positiveAttributeFilter?.displayForm.identifier === Md.VersionStd.attribute.displayForm.identifier ? filterVersion : obj))}
                                                    displayMode={displayMode}
                                                    custIndex={version}
                                                    key={`${version}-line`}
                                                    data={trendDataV[version]}
                                                    categories={trendCategoryTitles}
                                                    title={
                                                        printStatus
                                                            ? version
                                                            : `${version}
                                                                - ${countryLabel}
                                                                - ${filterChannel1.positiveAttributeFilter.in?.values[0]} - ${filterDuration.positiveAttributeFilter.in?.values[0]} - ${adjdeposititle ? adjdeposititle + "-" : ""} ${selectedKpi.name}`
                                                    }
                                                    subtitle={!printStatus ? basket.name : null}
                                                    height={printStatus ? 300 : 520}
                                                    width={printStatus ? 1200 : null}
                                                    chartProps={{
                                                        pdfMode: printStatus,
                                                        screenshotName: `basket-${basket.name}-trends-${version}`,
                                                        enableLegend: !printStatus,
                                                        marginBottom: printStatus ? 70 : 120,
                                                        menuButtonPosition: "inside",
                                                        // maxValue: maxTrendsValue * 1.1,
                                                        // minValue: minTrendsValue * 0.8,
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                    {printStatus && (
                                        <div className="legend h-min w-full grid grid-cols-2 gap-1">
                                            {Object.entries(colorVSeries).map(([name, color]) => (
                                                <div className="flex gap-2 items-center">
                                                    <div
                                                        style={{
                                                            width: "15px",
                                                            // height: "10px",
                                                            borderBottom: `solid ${getColorByCustId(name)}`,
                                                        }}
                                                    ></div>
                                                    <span className="text-xs font-normal">{getCustlabelByCustId(name)}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {printStatus && (
                                        <div className="fixed bottom-0">
                                            <BasketSummary userPref={userPreferenceContext.kpiSettings} kpi={selectedKpi} />
                                        </div>
                                    )}
                                </div>
                            ))
                        ))}
                </div>
            )}
            {/* {showVersionMatch && selectedVehicle && (
                <BasketVersionMatch
                    basket={basket}
                    Vehicle={selectedVehicle}
                    onCancel={() => setShowVersionMatch(false)}
                    seriesBy={[...seriesBy, Md.Emissions.Avg, Md.Doors.Avg]}
                    slicesBy={[Md.VersionStd, Md.CustId, Md.CorrModel, Md.VehSegment, Md.BodyStyle, Md.GearsPlus, Md.Fuel, Md.CorrMake, Md.VehType]}
                    filters={[filterCountryId, filterChannel1, filterDuration, filterMthCode]}
                    custSlots={custSlots}
                    kpi={selectedKpi}
                />
            )} */}
        </div>
    );
}
export { BasketViz, PdfHeader };
