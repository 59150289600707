import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { Typography } from "antd";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import FiltersContext from "../../contexts/Filters";
import MeasuresContext from "../../contexts/Measures";
import * as Md from "../../md/full";
import jsonPivot from "../../utils/jsonPivot";
import TableComponent from "../TableComponent";
import { useHistory } from "react-router-dom";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { useAppContext } from "../../contexts/AppContext";

const DAtable = ({ ...props }) => {
    const history = useHistory();
    const [Durationtable, setDurationtable] = useState([]);
    const [columns, setColumns] = useState([]);
    const { getCustlabelByCustId } = useAppContext();
    const {
        filterMthCode,
        filterCountryId,
        filterChannel1,
        filterMake,
        filterModel,
        filterFuel,
        filterCustId,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterCustmix,
        filterVersionStd,
        filterExtrapType,
        filterRolledForward,
        filterChannel3,
        filterCo2,
        filterListPrice,
        filterPs,
        filterKw,
        updateFilter,
        clearFilters,
    } = useContext(FiltersContext);
    const filters = [
        filterCountryId,
        filterMthCode,
        filterChannel1,
        filterMake,
        filterModel,
        filterFuel,
        filterCustId,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterCustmix,
        filterVersionStd,
        filterExtrapType,
        filterRolledForward,
        filterChannel3,
        filterCo2,
        filterListPrice,
        filterPs,
        filterKw,
    ];
    const { seriesBy } = useContext(MeasuresContext);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CustId, Md.Duration],
            filters: filters,
        },
    });

    useEffect(() => {
        const slices = result?.data()?.slices().toArray();
        const newdata = [];
        const data = slices?.map((slice) => {
            return {
                CustId: slice.sliceTitles()[0],
                Duration: slice.sliceTitles()[1],
                count: Number(slice.dataPoints()[28].rawValue),
            };
        });
        data?.forEach((item) => {
            const newitem = {
                CustId: item.CustId,
                Duration: item.Duration,
                count: item.count,
            };
            if (!newdata.some((e) => e.CustId === newitem.CustId && e.Duration === newitem.Duration)) {
                newdata.push(newitem);
            }
        });
        if (newdata.length > 0) {
            const dataSource = jsonPivot(newdata, { row: "Duration", column: "CustId", value: "count" });
            const newdataSource = dataSource.map((item) => {
                const total = Object.values(item)
                    .filter((m) => typeof m === "number")
                    .reduce((a, b) => a + b, 0);
                const newitem = {
                    ...item,
                    Total: total,
                };
                return newitem;
            });
            setDurationtable(newdataSource);
            setColumns(
                Object.keys(newdataSource[0]).map((m) => {
                    return {
                        title: () => <center>{["Duration", "Total"].includes(m) ? m : <img src={`/images/logos/Logo_${m}.png`} width={64} height={64} alt={m} />}</center>,
                        dataIndex: m,
                        key: m,
                        width: m === "Duration" ? 200 : 100,
                        fixed: ["Duration"].includes(m) ? "left" : null,
                        align: "center",
                        render: (cell, record) => {
                            if (typeof cell === "number" && m !== "Total") {
                                return (
                                    <div
                                        style={{
                                            cursor: "pointer",
                                            transition: "all 0.3s ease",
                                            padding: "4px 8px",
                                            borderRadius: "4px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                        onClick={() => {
                                            clearFilters();
                                            updateFilter("filterDuration", newPositiveAttributeFilter(Md.Duration, [record.Duration]));
                                            updateFilter("filterCustLabel", newPositiveAttributeFilter(Md.CustLabel, [getCustlabelByCustId(m)]));
                                            history.push("/vehicles");
                                        }}
                                        className="hover:bg-blue-100 hover:text-blue-700"
                                    >
                                        <Typography.Text strong style={{ fontSize: 12 }}>
                                            {cell}
                                        </Typography.Text>
                                    </div>
                                );
                            }
                            return (
                                <Typography.Text style={{ fontSize: 12 }} strong>
                                    {cell}
                                </Typography.Text>
                            );
                        },
                        filters: ["Duration"].includes(m)
                            ? null
                            : [
                                  {
                                      text: "Show values only",
                                      value: null,
                                  },
                              ],
                        onFilter: (value, record) => (["Duration"].includes(m) ? value : record[m] !== value),
                    };
                }),
            );
        }
    }, [result]);
    return status === "loading" ? (
        <LoadingComponent />
    ) : (
        <div>
            <TableComponent
                columns={columns}
                bordered
                className="border border-gray-200"
                dataSource={Durationtable}
                rowKey="Duration"
                size="small"
                pagination={false}
                scroll={{ x: columns.reduce((a, b) => a + b.width, 0), y: 400 }}
                widgetProps={{
                    id: "vehicle-available-duration",
                    section: "datacoverageadjusted",
                    filters,
                    metadata: {
                        Databy: "Duration",
                    },
                }}
                {...props}
            />
        </div>
    );
};

export default DAtable;
