import React, { useContext, useEffect, useMemo, useState } from "react";
import MakeConfigurator from "../components/BrandModelAnalysis/BrandConfigurator";
import { ColumnChartAnalysis } from "../components/BrandModelAnalysis/ColumnChartAnalysis";
import TrendBrandAnalysis from "../components/BrandModelAnalysis/TrendBrandAnalysis";
import MeasuresContext from "../contexts/Measures";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import { TbError404 } from "react-icons/tb";

import * as Md from "../md/full";
import FiltersContext from "../contexts/Filters";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import LoadingSpinner from "../components/LoadingSpinner";
import { CustIDByScope, useBrandConfigurator } from "../contexts/BrandConfiguratorProvider";
import { ColumnModelAnalysis } from "../components/BrandModelAnalysis/ColumnModelAnalysis";
import VersionAnalysisReport from "../components/BrandModelAnalysis/VersionAnalysisReport";
import _ from "lodash";
import { useAppContext } from "../contexts/AppContext";
import { calculateAvg, calculateMedian } from "../utils/calculationUtils";

const widgetHeight = 550;
const widgetStyle = `pt-1 px-1 border border-gray-300 rounded-md shadow-md min-h-[${widgetHeight}px]`;

const calculationModes = {
    average: {
        label: "Average",
        brandLabel: "Average",
        calculationMethod: (arr) => calculateAvg(arr),
    },
    median: {
        label: "Median",
        brandLabel: "Average of Median",
        calculationMethod: (arr) => calculateMedian(arr),
    },
};

const BrandModelAnalysis = (props) => {
    const { currentFilter, getFirstFilterValue, getFilterValue, getFiltersForFootnote, updateFilter } = useContext(FiltersContext);
    const { seriesBy, selectedKpi } = useContext(MeasuresContext);
    const { calculationMode } = useAppContext();
    const { filterSelectors, setFilterSelector, toGDFilters } = useBrandConfigurator();
    const countryId = getFirstFilterValue("filterCountryId");
    const oemPromoCustIDs = CustIDByScope[`${countryId}-${getFirstFilterValue("filterChannel1")}`]?.promo || [];
    const oemConfigCustIDs = CustIDByScope[`${countryId}-${getFirstFilterValue("filterChannel1")}`]?.config || [];
    // const custIdLabels = {
    //     [oemPromoCustID]: "OEM Promo",
    //     [oemConfigCustID]: "OEM Config"
    // };

    const oemFilters = [
        ...currentFilter.filter((filterObj) => filterObj?.positiveAttributeFilter?.in?.values.length > 0 && ![Md.CorrMake, Md.CorrModel, Md.CustId, Md.CustLabel].map((obj) => obj.attribute.displayForm.identifier).includes(filterObj?.positiveAttributeFilter.displayForm.identifier)),
        newPositiveAttributeFilter(Md.CustId, [...oemConfigCustIDs, ...oemPromoCustIDs]),
    ];
    const footnote = getFiltersForFootnote();
    // ?.split(' | ').filter(item => !(item.includes('Make') || item.includes('Model'))).join(" | ");

    const filters = [...oemFilters, ...toGDFilters()];
    const filtersTrends = filters.filter((obj) => obj?.positiveAttributeFilter.displayForm.identifier !== Md.MthCode.attribute.displayForm.identifier);

    const [corrMakeFilterValues, setCorrMakeFilterValues] = useState(getFilterValue("filterMake"));
    const [corrModelFilterValues, setCorrModelFilterValues] = useState(getFilterValue("filterModel"));

    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [Md.CorrMake, Md.CorrModel, Md.CustId, Md.VersionStd],
            filters: oemFilters,
        },
    });

    const makeModelObject = useMemo(() => {
        const slices = result?.data().slices().toArray();
        const mappedObj = slices?.reduce((acc, slice) => {
            const [make, model, custId, versionStd] = slice.sliceTitles();
            const versionObject = {
                label: versionStd,
                [custId]: slice.dataPoints().map((dp) => (dp.rawValue !== null ? Number(dp.rawValue) : null)),
            };
            if (!(make in acc)) {
                acc[make] = {
                    [model]: [versionObject],
                };
            } else {
                if (model in acc[make]) {
                    const index = acc[make][model].findIndex((obj) => obj.label === versionStd);
                    if (index !== -1) {
                        acc[make][model][index] = {
                            ...acc[make][model][index],
                            ...versionObject,
                        };
                    } else {
                        acc[make][model] = [...acc[make][model], versionObject];
                    }
                } else {
                    acc[make][model] = [versionObject];
                }
            }
            return acc;
        }, {});
        // Group the config and promo, by selecting only the min
        const res = mappedObj
            ? Object.entries(mappedObj).reduce((acc, [make, modelsObj]) => {
                  const modelObjCopy = Object.entries(modelsObj).reduce((accModel, [model, versionsArr]) => {
                      const versionArrCopy = versionsArr.map((versionObj) => ({
                          ...versionObj,
                          config: _.min(oemConfigCustIDs.map((custId) => (custId in versionObj ? versionObj[custId][selectedKpi.index] : null))) || null,
                          promo: _.min(oemPromoCustIDs.map((custId) => (custId in versionObj ? versionObj[custId][selectedKpi.index] : null))) || null,
                      }));
                      return {
                          ...accModel,
                          [model]: versionArrCopy,
                      };
                  }, {});
                  return {
                      ...acc,
                      [make]: modelObjCopy,
                  };
              }, {})
            : {};

        return res;
    }, [result, status, selectedKpi]);

    const generateSubTitle = ({ isTrends = false }) => {
        return [countryId, getFirstFilterValue("filterDuration"), getFirstFilterValue("filterChannel1"), !isTrends ? getFirstFilterValue("filterMthCode") : null, selectedKpi.name, ["UK", "GB"].includes(countryId) ? "GBP" : "EUR"].filter((item) => item !== null).join(", ");
    };

    useEffect(() => {
        // When makeModel list changed, sync the values of the selected filters
        // @ts-ignore
        if (Object.keys(makeModelObject).length > 0 && Object.keys(filterSelectors).length > 0) {
            const configuratorSynced = Object.keys(filterSelectors).reduce((acc, make) => {
                if (make in makeModelObject) {
                    acc[make] = Object.keys(filterSelectors[make]).reduce((accMake, model) => {
                        const versionsArr = filterSelectors[make][model].filter((versionObj) => make in makeModelObject && model in makeModelObject[make] && makeModelObject[make][model].map((obj) => obj.label).includes(versionObj.label));
                        const syncedVersions = versionsArr.map((versionObj) => makeModelObject[make][model].find((item) => item.label === versionObj.label));
                        if (model in makeModelObject[make]) accMake[model] = syncedVersions.length > 0 ? syncedVersions : makeModelObject[make][model];
                        return accMake;
                    }, {});
                }
                return acc;
            }, {});
            // Object.keys(configuratorSynced).forEach(make => {
            //     Object.keys(configuratorSynced[make]).forEach(model => {
            //         configuratorSynced[make][model] = makeModelObject[make][model].filter(versionStdObj => filterSelectors[make][model].map(obj => obj.label).includes(versionStdObj.label));
            //     });
            // });
            setFilterSelector(configuratorSynced);
        }
    }, [makeModelObject]);

    useEffect(() => {
        // Reset the filters for make and model
        updateFilter("filterMake", newPositiveAttributeFilter(Md.CorrMake, []));
        updateFilter("filterModel", newPositiveAttributeFilter(Md.CorrMake, []));
        // return () => {
        //     if(corrMakeFilterValues.length > 0) updateFilter("filterMake", newPositiveAttributeFilter(Md.CorrMake, corrMakeFilterValues));
        //     if(corrModelFilterValues.length > 0) updateFilter("filterModel", newPositiveAttributeFilter(Md.CorrModel, corrModelFilterValues));
        // }
    }, []);

    // useEffect(() => {
    //     // Sync query filters
    //     if (Object.keys(filterSelectors).length > 0) {
    //         const brands = Object.keys(filterSelectors);
    //         const models = brands.reduce((acc,brand) => {
    //             return [...acc, ...Object.keys(filterSelectors[brand])];
    //         }, []);
    //         updateFilter("filterMake", newPositiveAttributeFilter(Md.CorrMake, brands));
    //         updateFilter("filterModel", newPositiveAttributeFilter(Md.CorrModel, models));
    //     }
    //     return () => {
    //         updateFilter("filterMake", newPositiveAttributeFilter(Md.CorrMake, []));
    //         updateFilter("filterModel", newPositiveAttributeFilter(Md.CorrModel, []));
    //     }
    // }, [filterSelectors]);

    return status === "loading" ? (
        <LoadingSpinner />
    ) : makeModelObject ? (
        <div className="grid grid-cols-12 gap-4 grid-rows-2">
            {Object.keys(filterSelectors).length > 0 ? (
                <>
                    <div className={`col-span-4 ${widgetStyle}`}>
                        <ColumnChartAnalysis
                            filters={filters}
                            calculationMode={calculationMode}
                            oemPromos={oemPromoCustIDs}
                            oemConfigs={oemConfigCustIDs}
                            title={`Monthly Rate ${calculationModes[calculationMode].brandLabel} by Brand`}
                            subtitle={generateSubTitle({ isTrends: false })}
                            footnote={footnote}
                        />
                    </div>
                    <div className={`col-span-5 ${widgetStyle}`}>
                        <TrendBrandAnalysis
                            filters={filtersTrends}
                            calculationMode={calculationMode}
                            oemPromo={oemPromoCustIDs}
                            oemConfig={oemConfigCustIDs}
                            title={`Monthly Rate ${calculationModes[calculationMode].brandLabel} Trends by Brand`}
                            subtitle={generateSubTitle({ isTrends: true })}
                            footnote={footnote}
                        />
                    </div>
                </>
            ) : (
                <div className="col-span-8 flex flex-col items-center justify-center gap-2">
                    <span className="text-xl font-bold text-indigo-700">Quickly compare models and versions</span>
                    <span className="text-lg font-medium text-indigo-700">Select the brands, models or versions in the Selection box at the right</span>
                </div>
            )}
            <div className={Object.keys(filterSelectors).length > 0 ? "col-span-3" : "col-span-4"}>
                <MakeConfigurator makeModelObject={makeModelObject} height={widgetHeight} oemPromos={oemPromoCustIDs} oemConfigs={oemConfigCustIDs} />
            </div>

            {Object.keys(filterSelectors).length > 0 && (
                <div className={`col-span-full ${widgetStyle} max-h-[${widgetHeight + 10}px]`}>
                    <ColumnModelAnalysis
                        filters={filters}
                        oemPromos={oemPromoCustIDs}
                        oemConfigs={oemConfigCustIDs}
                        calculationMode={calculationMode}
                        calculationHandler={calculationModes[calculationMode].calculationMethod}
                        title={`Monthly Rate ${calculationModes[calculationMode].label} by Model`}
                        subtitle={generateSubTitle({ isTrends: false })}
                        footnote={footnote}
                        height={520}
                    />
                </div>
            )}
            {Object.keys(filterSelectors).length > 0 && (
                <div className={`col-span-full ${widgetStyle} max-h-[${widgetHeight + 10}px]`}>
                    <VersionAnalysisReport filterSelectors={filterSelectors} filters={filters} title={"Monthly Rate by Version"} subtitle={generateSubTitle({ isTrends: false })} footnote={footnote} height={520} />
                </div>
            )}
        </div>
    ) : (
        <div className="flex items-center gap-2 justify-center">
            <TbError404 className="w-10 h-10 text-steel" />
            <span className="text-lg font-medium text-indigo-500">These views are compatible only with the OEMs, make sure that the activated filters are compatible with the OEM data</span>
        </div>
    );
};

export { BrandModelAnalysis, calculationModes };
