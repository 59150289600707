import React, { useContext, useEffect, useState } from "react";
import { useExecutionDataView } from "@gooddata/sdk-ui";

import { newJsonPivot } from "../../utils/jsonPivot";
import FiltersContext from "../../contexts/Filters";
import VolumeTrend from "./VolumeTrend";
import TrendIndices from "./LineIndices";
import Widget from "../Widget";
import MeasuresContext from "../../contexts/Measures";
import { useAppContext } from "../../contexts/AppContext";
import { colors } from "../../constants";
import LoadingSpinner from "../LoadingSpinner";

// @ts-ignore
const TrendEvolutionView = React.forwardRef(({ startMonth = null, seriesBy, slicesBy, filters, selectedKpi, displayMode }, ref) => {
    const { getFilterValue, getFiltersForFootnote } = useContext(FiltersContext);
    const { getScopeForTitleHistory } = useContext(MeasuresContext);
    const { getColorByCustId } = useAppContext();
    const ctryId = getFilterValue("filterCountryId")[0];
    const [rentalEvolData, setRentalEvolData] = useState([]);
    const [volumeData, setVolumeData] = useState([]);
    // Category colors passed to the nested chart
    const [categoryColors, setCategoryColors] = useState([]);

    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy,
            filters,
        },
    });

    const chartFootnote = getFiltersForFootnote();

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        if (slices) {
            let rentalEvolByPlayer = slices?.map((slice) => ({
                mthCode: slice.sliceTitles()[0],
                Player: slice.sliceTitles()[1],
                // versionStd: slice.sliceTitles()[2],
                value: slice.dataPoints()[selectedKpi.index].rawValue !== null ? selectedKpi.roundValue(Number(slice.dataPoints()[selectedKpi.index].rawValue)) : null,
                versionCount: slice.dataPoints()[28].rawValue !== null ? Number(slice.dataPoints()[28].rawValue) : null,
            }));
            if (startMonth !== null) {
                rentalEvolByPlayer = rentalEvolByPlayer.filter((obj) => obj.mthCode.localeCompare(startMonth) !== -1);
            }
            const categoryColors = result
                ?.meta()
                .allHeaders()[0][1]
                .reduce((acc, nextHeader, index) => {
                    const headerTitle = nextHeader?.attributeHeaderItem?.name;
                    if (!acc.find((item) => item?.name === headerTitle)) {
                        acc.push(
                            Object.assign({
                                name: headerTitle,
                                color: displayMode.key === "Player" ? getColorByCustId(headerTitle) : colors[index],
                            }),
                        );
                    }
                    return acc;
                }, []);
            setRentalEvolData(newJsonPivot(rentalEvolByPlayer, { row: "Player", column: "mthCode", value: "value" }));
            setVolumeData(newJsonPivot(rentalEvolByPlayer, { row: "Player", column: "mthCode", value: "versionCount" }));
            setCategoryColors(categoryColors);
        }
    }, [result, startMonth, selectedKpi]);

    return status === "loading" ? (
        <LoadingSpinner />
    ) : (
        rentalEvolData.length > 0 && (
            <div className="space-y-8">
                <article>
                    <Widget
                        title={`Evolution of rental averages - ${getScopeForTitleHistory(selectedKpi.name)}`}
                        subtitle={
                            <span className="pb-2 inline flex items-center gap-2 text-secondary-500 text-sm font-semibold">
                                {/* <IoMdInformation className="w-5 h-5 cursor-help" /> */}
                                The rental averages may fluctuate over time due to changes in vehicle mix, as opposed to price index
                            </span>
                        }
                        description={"Rental averages over time"}
                        footnote={chartFootnote}
                        handleWidgetVisibility={true}
                    >
                        <>
                            <TrendIndices
                                mode={"rentalEvol"}
                                data={rentalEvolData}
                                calculation={(obj, startMonth) =>
                                    Object.entries(obj)
                                        .filter(([key, elem]) => key !== "Player")
                                        .map(([key, elem]) => elem)
                                }
                                startMonth={startMonth}
                                displayBy={displayMode.key}
                                dpTitleHandler={displayMode.formatTitle}
                                categoryColors={categoryColors}
                                filters={filters}
                            />
                        </>
                    </Widget>
                </article>

                {volumeData.length > 0 && (
                    <Widget
                        title={`Overall Vehicles Volumes - ${getScopeForTitleHistory(selectedKpi.name)}`}
                        description={"The index shows the monthly rate evolution vs the starting month ( Analysis is made with iso-version month over month )"}
                        footnote={chartFootnote}
                        handleWidgetVisibility={true}
                    >
                        <VolumeTrend mode={"rentalEvol"} data={volumeData} displayBy={displayMode.key} dpTitleHandler={displayMode.formatTitle} categoryColors={categoryColors} filters={filters} width={ref?.current?.getBoundingClientRect().width} />
                    </Widget>
                )}
            </div>
        )
    );
});

export default TrendEvolutionView;
