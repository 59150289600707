import { Switch, Tooltip } from "antd";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import * as Md from "../../md/full";
import FiltersContext, { displayModes } from "../../contexts/Filters";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { gql, useQuery } from "@apollo/client";
import { useUserContext } from "../../contexts/User";
import { Combobox } from "@headlessui/react";
import { useAppContext } from "../../contexts/AppContext";
import { ArrowDownIcon } from "../Icons/Arrows";
import { CheckIcon } from "@heroicons/react/24/outline";
// @ts-ignore
import { Link } from "react-router-dom";
import ModeDisplayFilter from "../Filters/ModeDisplayFilter";
import { AiOutlineEdit } from "react-icons/ai";
import MeasuresContext from "../../contexts/Measures";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const catalogOptions = [
    {
        id: "version",
        label: "Version",
        value: true,
    },
    {
        id: "model",
        label: "Model/Energy",
        value: false,
    },
];

const trendModeOptions = [
    {
        label: "Rental indices",
        value: "price-index",
    },
    {
        label: "Rental average",
        value: "rental-average",
    },
];

const calculationModes = [
    {
        label: "Average",
        value: "average",
    },
    {
        label: "Median",
        value: "median",
    },
];

const settingsAliases = {
    flag_ser: "Maintenance",
    flag_bre: "Breakdown",
    flag_ins: "Insurance",
    flag_rep: "Replacement",
    flag_fin: "Financial loss",
    flag_co2: "CO2 Malus",
    flag_tyr: "Tyres",
    flag_win: "Winter Tyres",
    flag_evb: "EV Bonus",
};

const ENABLED_SETTINGS = gql`
    query ($ctryid: String, $channel1: String) {
        re_rentaleye_re_services_settings(where: { ctryid: { _eq: $ctryid }, channel: { _eq: $channel1 }, category: { _eq: "Settings" } }) {
            flag_ser
            flag_bre
            flag_ins
            flag_rep
            flag_fin
            flag_co2
            flag_tyr
            flag_win
            flag_evb
        }
    }
`;

const Services = (settings, IncServices) => {
    const services =
        settings &&
        Object.keys(settings)
            ?.filter((key) => settings[key] === 1)
            .map((key) => settingsAliases[key]);
    return IncServices ? (services?.length > 0 ? `${services.join(", ")}` : "No service selected in the settings: financial rental are displayed") : "Services same as original offer and not harmonized among players";
};

function Section(path, colSpan = 3) {
    const {
        startingMonth,
        tabindex,
        dateRange,
        setTabindex,
        setStartingMonth,
        monthList,
        benchmarkList,
        benchmarkReference,
        setDateRange,
        setBenchmarkReference,
        showListPrices,
        setShowListPrices,
        showExtendedTable,
        setShowExtendedTable,
        trendsMode,
        setTrendsMode,
        calculationMode,
        setCalculationMdode,
    } = useAppContext();
    const { showVersionCatalog, setShowVersionCatalog, user, updateMultiSelection } = useUserContext();
    const { displayMode, changeMode, showOption, changeShowOption, showAsOptions } = useContext(FiltersContext);
    const [loading, setLoading] = useState(false);
    const { selectedKpi } = useContext(MeasuresContext);

    const onSelectRange = useCallback(
        (rangeHorizon) => {
            if (rangeHorizon) setDateRange(rangeHorizon);
            else setDateRange([]);
        },
        [dateRange],
    );

    useEffect(() => {
        if (monthList?.length > 0) setStartingMonth(monthList[0]);
    }, [monthList, setStartingMonth]);

    useEffect(() => {
        if (selectedKpi.name === "List Price") setShowListPrices(false);
    }, [selectedKpi]);

    function onBenchmarkUpdate(event) {
        setBenchmarkReference(event.target.value);
    }
    const disabledDate = (current) => {
        return current && current > moment().add({ days: 1 });
    };

    const sectionParams = {
        Trends: {
            path: "/trends",
            component: () => (
                <div className="flex flex-col items-start gap-8 w-full">
                    <label className="font-medium text-sm text-indigo-700">Starting Month</label>
                    <Combobox
                        value={startingMonth}
                        onChange={(e) => {
                            setStartingMonth(e);
                        }}
                    >
                        <div className="relative w-full">
                            <Combobox.Input displayValue={(item) => item} inputMode="none" className="w-full text-indigo-700 font-semibold cursor-pointer rounded-md border shadow-button border-indigo-700 py-1 pl-3 pr-10 text-sm" />
                            <Tooltip title={"Define base 100 of the trend analysis graph"} placement="topRight" color="#9C8ACE">
                                <Combobox.Button className="absolute inset-y-0 right-0  flex items-center rounded-r-md px-2">
                                    <ArrowDownIcon width={20} height={15} fill={"#0a0d79"} />
                                </Combobox.Button>
                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base">
                                    {monthList?.map((month) => (
                                        <Combobox.Option
                                            key={month}
                                            value={month}
                                            as={Fragment}
                                            // @ts-ignore
                                            className={({ active }) => (active ? "bg-backgroundColor" : "") + " cursor-default text-indigo-700 font-semibold text-sm py-2 px-3"}
                                        >
                                            {({
                                                // @ts-ignore
                                                active,
                                                selected,
                                            }) => {
                                                return (
                                                    <div className="flex">
                                                        <span className="flex justify-between">
                                                            {month}
                                                            {selected && (
                                                                <div>
                                                                    <CheckIcon className="h-5 w-5" color="#490fcc" />
                                                                </div>
                                                            )}
                                                        </span>
                                                    </div>
                                                );
                                            }}
                                        </Combobox.Option>
                                    ))}
                                </Combobox.Options>
                            </Tooltip>
                        </div>
                    </Combobox>
                </div>
            ),
        },
        CarSelector: {
            path: "/vehicles",
            component: () => (
                <div className="grid grid-cols-[140px_minmax(0,_1fr)] gap-4">
                    <span className="font-medium text-sm text-indigo-700">Display catalog by:</span>
                    <div className="flex flex-row gap-2 items-center">
                        {catalogOptions.map((item) => (
                            <fieldset className="flex flex-row gap-1 items-center">
                                <input
                                    type="radio"
                                    name="showCatalog"
                                    id={item.id}
                                    style={{
                                        accentColor: "#6B50B6",
                                    }}
                                    onChange={() => setShowVersionCatalog(item.value)}
                                    checked={item.value === showVersionCatalog}
                                />
                                <label htmlFor={item.id} className={(item.value === showVersionCatalog ? "text-indigo-500" : "cursor-pointer hover:underline hover:text-steel") + "text-sm font-medium"}>
                                    {item.label}
                                </label>
                            </fieldset>
                        ))}
                    </div>
                    <span className="self-center font-medium text-sm text-indigo-700">Set Trends range:</span>
                    {/* @ts-ignore */}
                    <RangePicker
                        format={"YYYY-MM-DD"}
                        onChange={onSelectRange}
                        defaultValue={dateRange}
                        disabledDate={disabledDate}
                        maxDate={moment().format("YYYY-MM-DD")}
                        style={{
                            borderColor: "#AEAEAE",
                        }}
                    />
                    <div className="flex gap-4">
                        <div className="flex flex-row gap-4 items-center">
                            <span className="font-medium text-sm text-indigo-700 whitespace-nowrap">Show List Prices :</span>
                            <Switch
                                checked={showListPrices}
                                disabled={selectedKpi.name === "List Price"}
                                onChange={() => setShowListPrices(!showListPrices)}
                                size="small"
                                style={{
                                    backgroundColor: showListPrices ? "#3a0ca3" : "#EEF1FF",
                                }}
                            />
                        </div>
                        <div className="flex flex-row gap-4 items-center">
                            <span className="font-medium text-sm text-indigo-700 whitespace-nowrap">Enable Multi-Selection:</span>
                            <Switch
                                checked={user?.enable_multiselection}
                                onChange={(checked) => {
                                    setLoading(true);
                                    updateMultiSelection(checked).then(() => setLoading(false));
                                }}
                                size="small"
                                style={{
                                    backgroundColor: user?.enable_multiselection ? "#3a0ca3" : "#EEF1FF",
                                }}
                                loading={loading}
                                disabled={loading}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        Competitivness: {
            path: "/competitiveness",
            component: () => (
                <div className="flex flex-col gap-8 w-full">
                    <span className="font-medium text-sm text-indigo-700">Choose Benchmark</span>
                    <select name="benchmark" onChange={onBenchmarkUpdate} value={benchmarkReference} className="w-full py-0.5 pl-2 border text-indigo-700 font-semibold border-indigo-700 rounded outline-indigo-700">
                        <option disabled value={""}></option>
                        {benchmarkList.map((m) => (
                            <option key={m} value={m} className="text-indigo-700 font-semibold font-sans">
                                {m}
                            </option>
                        ))}
                    </select>
                </div>
            ),
        },
        Export: {
            path: "/export",
            component: () => (
                <div className="flex flex-col gap-8">
                    <label className="font-medium text-sm text-indigo-700">Display Table By :</label>
                    <nav className="flex gap-4" aria-label="Tabs" id="tabs">
                        <div>
                            <input className="accent-indigo-700 cursor-pointer" type="radio" checked={tabindex === 0} onChange={() => setTabindex(0)} />
                            <span className="font-medium ml-2 text-sm cursor-pointer hover:underline" onClick={() => setTabindex(0)}>
                                Quote
                            </span>
                        </div>
                        <div>
                            <input className="accent-indigo-700 cursor-pointer" type="radio" checked={tabindex === 1} onChange={() => setTabindex(1)} />
                            <span className="font-medium ml-2 text-sm cursor-pointer hover:underline" onClick={() => setTabindex(1)}>
                                Vehicle
                            </span>
                        </div>
                    </nav>
                </div>
            ),
        },
        Basket: {
            path: "/baskets",
            component: () => (
                <div className="grid grid-cols-[140px_minmax(0,_1fr)] gap-4">
                    <div className="col-span-2">
                        <ModeDisplayFilter inputName={"displayMode"} inputLabel={"Display By"} elements={displayModes} defaultElement={displayMode} onChange={changeMode} helpMessage="Plot data using this attribute" />
                    </div>
                    <div className="col-span-2">
                        <ModeDisplayFilter inputName={"viewBy"} inputLabel={"Show as"} elements={showAsOptions} defaultElement={showOption} onChange={changeShowOption} helpMessage="View by" />
                    </div>
                    <span className="font-medium text-sm text-indigo-700 self-center">Set Trends range:</span>
                    <RangePicker
                        format={"YYYY-MM-DD"}
                        onChange={onSelectRange}
                        defaultValue={dateRange}
                        disabledDate={disabledDate}
                        style={{
                            borderColor: "#AEAEAE",
                        }}
                    />
                    <span className="font-medium text-sm text-indigo-700">Show List Prices :</span>
                    <Switch
                        className="w-min"
                        checked={showListPrices}
                        onChange={() => setShowListPrices(!showListPrices)}
                        disabled={selectedKpi.name === "List Price"}
                        size="small"
                        style={{
                            backgroundColor: showListPrices ? "#3a0ca3" : "#EEF1FF",
                        }}
                    />
                </div>
            ),
        },
        TrendSwitchSection: {
            path: "/trends-switch",
            component: () => (
                <div className="flex flex-col gap-8">
                    <label className="font-medium text-sm text-indigo-700">Show trends as</label>
                    <div className="flex flex-row gap-4 items-center">
                        {trendModeOptions.map((item) => (
                            <fieldset className="flex flex-row gap-1 items-center">
                                <input
                                    type="radio"
                                    name="showCatalog"
                                    id={item.value}
                                    style={{
                                        accentColor: "#6B50B6",
                                    }}
                                    onChange={() => setTrendsMode(item.value)}
                                    checked={item.value === trendsMode}
                                />
                                <label htmlFor={item.value} className={(item.value === trendsMode ? "text-indigo-500" : "cursor-pointer hover:underline hover:text-steel") + " text-sm font-medium"}>
                                    {item.label}
                                </label>
                            </fieldset>
                        ))}
                    </div>
                </div>
            ),
        },
        Promotions: {
            path: "/oemoffers",
            component: () => (
                <div className="flex flex-col gap-8">
                    <span className="font-medium text-sm text-indigo-700">Display Promotions :</span>
                    <div className="flex flex-row gap-8 justify-around items-center">
                        <fieldset className="flex flex-row w-full gap-1 items-center">
                            <input type="radio" name="showExtendedTable" id="condensed" style={{ accentColor: "#6B50B6" }} onChange={() => setShowExtendedTable(false)} checked={!showExtendedTable} />
                            <label htmlFor="condensed" className={(!showExtendedTable ? "text-indigo-500" : "cursor-pointer hover:underline hover:text-steel") + "text-sm font-medium"}>
                                Condensed
                            </label>
                        </fieldset>
                        <fieldset className="flex flex-row w-full gap-1 items-center">
                            <input type="radio" name="showExtendedTable" id="extended" style={{ accentColor: "#6B50B6" }} onChange={() => setShowExtendedTable(true)} checked={showExtendedTable} />
                            <label htmlFor="extended" className={(showExtendedTable ? "text-indigo-500" : "cursor-pointer hover:underline hover:text-steel") + "text-sm font-medium"}>
                                Extended
                            </label>
                        </fieldset>
                    </div>
                </div>
            ),
        },
        brandModelAnalysis: {
            path: "/brand_model_analysis",
            component: () => (
                <div className="flex flex-col gap-8">
                    <label className="font-medium text-sm text-indigo-700">Calculation mode</label>
                    <div className="flex flex-row gap-8 items-center">
                        {calculationModes.map((item) => (
                            <fieldset className="flex flex-row gap-1 items-center">
                                <input
                                    type="radio"
                                    name="showCatalog"
                                    id={item.value}
                                    style={{
                                        accentColor: "#6B50B6",
                                    }}
                                    onChange={() => setCalculationMdode(item.value)}
                                    checked={item.value === calculationMode}
                                />
                                <label htmlFor={item.value} className={(item.value === calculationMode ? "text-indigo-500" : "cursor-pointer hover:underline hover:text-steel") + " text-sm font-medium"}>
                                    {item.label}
                                </label>
                            </fieldset>
                        ))}
                    </div>
                </div>
            ),
        },
    };

    const currentSection = Object.entries(sectionParams).find(([_, value]) => value.path === path);

    if (!currentSection) {
        return;
    }

    // @ts-ignore
    const [title, { component: Component }] = currentSection;

    return (
        <div className={`flex flex-col items-start w-full col-span-${colSpan} rounded-md border border-indigo-500 p-2 shadow-md`}>
            <Component />
        </div>
    );
}

function Parameters() {
    const [loading, setLoading] = useState(false);
    const { filterCountryId, filterChannel1, updateFilter, Extrap, setExtrap } = useContext(FiltersContext);
    const { kpiSettings, IncServices, enabledKpisettings, setEnabledKpisettings, updateServices, userPrefs, updateRollForward } = useUserContext();
    // @ts-ignore
    const ctryid = filterCountryId?.positiveAttributeFilter?.in?.values[0];
    // @ts-ignore
    const channel1 = filterChannel1?.positiveAttributeFilter?.in?.values[0];
    // @ts-ignore
    const fl_rw = userPrefs?.find((item) => item.ctryid === ctryid && item.channel1 === channel1)?.fl_rw;
    const { data: enabledServices } = useQuery(ENABLED_SETTINGS, {
        variables: { ctryid, channel1 },
    });

    useEffect(() => {
        const activeFlags = enabledServices?.re_rentaleye_re_services_settings[0];
        if (activeFlags && Object.keys(kpiSettings).length > 0) {
            const userFlags = Object.entries(kpiSettings)
                .filter(([key, value]) => key in activeFlags && activeFlags[key] === 1)
                .reduce(
                    (acc, [key, value]) =>
                        Object.assign({
                            ...acc,
                            [key]: value,
                        }),
                    {},
                );
            setEnabledKpisettings({ ...userFlags });
        }
    }, [enabledServices, kpiSettings]);

    function UpdateExtrapolation(param) {
        setLoading(true);
        if (Extrap.includes(param)) {
            setExtrap(Extrap.filter((item) => item !== param));
        } else {
            setExtrap([...Extrap, param]);
        }
    }

    useEffect(() => {
        updateFilter("filterExtrapType", newPositiveAttributeFilter(Md.Extraptype, Extrap));
        setLoading(false);
    }, [Extrap, updateFilter]);

    function SwitchItem({ label, checked, onChange }) {
        return (
            <div className="flex justify-between items-center">
                <label className="text-sm font-medium">{label}</label>
                <Switch
                    onChange={onChange}
                    checked={checked}
                    size="small"
                    style={{
                        backgroundColor: checked ? "#3a0ca3" : "#EEF1FF",
                    }}
                    loading={loading}
                />
            </div>
        );
    }

    return (
        <div className="flex gap-1">
            <div className="w-24">
                <label className="font-bold text-base text-indigo-700">Parameters</label>
            </div>
            <div className={`grid ${window.location.pathname !== "/baskets" ? "grid-cols-parameters" : "grid-cols-basketparams"} gap-3 w-fit`}>
                {!["/trends", "/oemoffers"].includes(window.location.pathname) && (
                    <>
                        <div className="flex flex-col gap-2 col-span-2 rounded-md border border-indigo-500 p-2 shadow-md">
                            <span className="font-medium text-sm text-indigo-700">Extrapolation</span>
                            <div className="flex flex-col gap-2">
                                <SwitchItem
                                    label="Mileage-Duration"
                                    checked={Extrap.includes("Age/Km Extrap")}
                                    onChange={() => {
                                        UpdateExtrapolation("Age/Km Extrap");
                                    }}
                                />
                                <SwitchItem
                                    label="Similar Versions"
                                    checked={Extrap.includes("Version Extrap")}
                                    onChange={() => {
                                        UpdateExtrapolation("Version Extrap");
                                    }}
                                />
                                <SwitchItem
                                    label="Rolled Forward"
                                    checked={fl_rw}
                                    onChange={(checked) => {
                                        setLoading(true);
                                        updateRollForward(checked, ctryid, channel1).then(() => setLoading(false));
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col col-span-3 gap-8 rounded-md border border-indigo-500 p-2 shadow-md">
                            <div className="flex flex-row items-center gap-4">
                                <label className="text-sm font-medium text-indigo-700">Service Adjustment</label>
                                <Switch
                                    onChange={(checked) => {
                                        updateServices(checked);
                                    }}
                                    checked={IncServices}
                                    size="small"
                                    style={{
                                        backgroundColor: IncServices ? "#3a0ca3" : "#EEF1FF",
                                        accentColor: "#3a0ca3",
                                    }}
                                    loading={loading}
                                />
                            </div>
                            <div className="flex flex-row gap-2 items-center">
                                <Link to={"/settings/#rentalsettings"}>
                                    <AiOutlineEdit className="text-indigo-700 w-6 h-6 hover:opacity-50 cursor-pointer" />
                                </Link>
                                <span className="text-xs text-gray-400 font-medium">{Services(enabledKpisettings, IncServices)}</span>
                            </div>
                        </div>
                    </>
                )}
                {window.location.pathname === "/trends" && Section("/trends-switch", 2)}
                {Section(window.location.pathname, window.location.pathname === "/trends" ? 2 : 3)}
            </div>
        </div>
    );
}

export default Parameters;
