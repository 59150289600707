import * as React from "react";
const MagicWand = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlSpace="preserve" {...props}>
        <path
            style={{
                strokeWidth: 3,
                strokeLinecap: "round",
                strokeMiterlimit: 10,
            }}
            className="fill-none stroke-indigo-900"
            d="M33.5 10.5v6m3-3h-6m-21-7v6m3-3h-6m7 22v6m3-3h-6"
        />
        <path className="fill-indigo-900" d="m22.05 18.81-4.19 4.3-2.92-2.92a3.01 3.01 0 0 1 0-4.25 3 3 0 0 1 2.12-.88c.77 0 1.54.3 2.13.88zm20 24.24c-.59.59-1.35.88-2.12.88s-1.53-.29-2.12-.88L19.99 25.23l4.19-4.3 17.87 17.88a3 3 0 0 1 0 4.24" />
    </svg>
);
export default MagicWand;
