const mode = (arr) => {
    const store = {};
    arr.forEach((num) => (store[num] ? (store[num] += 1) : (store[num] = 1)));
    return Object.keys(store).sort((a, b) => (store[b] > store[a] ? 1 : -1))[0];
};

function toPivot({ dataTable = [], categoryLabel = undefined, comparatorLabel = undefined, valueLabel = undefined, additionalColumns = [], listPriceLabel = "List_Price" }) {
    let columns = [categoryLabel, ...additionalColumns, listPriceLabel];
    let distinctVehicles = [];
    let pivotData = [];
    // Remove unecessary columns
    let data = dataTable.map((item) => {
        return Object.entries(item)
            .filter(([key, value]) => [...additionalColumns, comparatorLabel, categoryLabel, valueLabel, listPriceLabel].includes(key))
            .reduce((acc, nextValue) => {
                acc = {
                    ...acc,
                    [nextValue[0]]: nextValue[1],
                };
                return acc;
            }, {});
    });
    data.forEach((dataElem) => {
        if (!distinctVehicles.find((elem) => elem[categoryLabel] === dataElem[categoryLabel])) {
            distinctVehicles.push(dataElem);
        }
    });
    for (let vehicleObj of distinctVehicles) {
        let dataVehicles = data.filter((elem) => elem[categoryLabel] === vehicleObj[categoryLabel]);
        let transposedData = dataVehicles.reduce((acc, nextVal) => {
            !columns.includes(nextVal[comparatorLabel]) && columns.push(nextVal[comparatorLabel]);
            return Object.assign({
                ...acc,
                [listPriceLabel]: listPriceLabel in acc && acc[listPriceLabel]?.length > 0 ? [...acc[listPriceLabel], nextVal[listPriceLabel]] : [nextVal[listPriceLabel]],
                [nextVal[comparatorLabel]]: Number(nextVal[valueLabel]) > 0 ? Number(nextVal[valueLabel]) : null,
            });
        }, vehicleObj);
        delete transposedData[comparatorLabel];
        delete transposedData[valueLabel];
        const modeListPrice = mode(transposedData[listPriceLabel]);
        transposedData[listPriceLabel] = Number(modeListPrice) > 0 ? Number(modeListPrice) : null;
        pivotData.push(transposedData);
    }
    return {
        columns: columns,
        data: pivotData,
    };
}

function calculateMedian(arrList) {
    arrList.sort((a, b) => a - b);
    const setLength = arrList.length;
    const middlePosition = setLength / 2;
    if (setLength % 2 !== 0) {
        return arrList[Math.floor(middlePosition)];
    }
    return (arrList[middlePosition] + arrList[middlePosition - 1]) / 2;
}

function calculateAvg(arrList) {
    return Number(arrList.reduce((acc, item) => acc + item, 0) / arrList.length);
}

function weigthedAverage(arrValues, coefs) {
    const var1 = arrValues.reduce((acc, nextItem, index) => {
        return acc + nextItem * coefs[index];
    }, 0);
    const coefSum = coefs.reduce((acc, nextCoef) => acc + nextCoef, 0);
    return var1 / coefSum;
}

function weightedAverageByMake(models, kpi, modelCalculMethod, custIDType = "promo") {
    // Get the minimum value for each offer
    const [sumValues, modelsCount] = Object.values(models).reduce(
        (acc, versions) => {
            const versionsArr = versions.filter((obj) => obj !== null && obj[custIDType] > 0);
            if (versionsArr.length === 0) return acc;
            else {
                const modelValue = modelCalculMethod(versionsArr.map((obj) => obj[custIDType]));
                return [acc[0] + modelValue, acc[1] + 1];
            }
        },
        [0, 0],
    );
    return kpi.id === 4 ? Number(((sumValues / modelsCount) * 100).toFixed(2)) : kpi.roundValue(sumValues / modelsCount) || null;
}

function medianByMake(models, kpi, modelCalculMethod, custIDType = "promo") {
    // Get the minimum value for each offer
    const modelsMedians = Object.values(models).map((versions) => {
        const versionsArr = versions.filter((obj) => obj !== null && obj[custIDType] > 0);
        return versionsArr.length !== 0 ? modelCalculMethod(versionsArr.map((obj) => obj[custIDType])) : null;
    });

    return calculateMedian(modelsMedians) !== null ? kpi.roundValue(calculateMedian(modelsMedians)) : null;
}

function calculateModelAvg(versionObjArr = [], custIds = [], calculMethod) {
    /**
     * Method to calculate the avg of a specific model,
     * custIds: list of custIds used to get the minimum offer for each version of the model
     */
    const groupByVersion = versionObjArr.reduce((acc, obj) => {
        // get the minimum for each offer
        if (obj.versionStd in acc && custIds.includes(obj.custId) && obj.value !== null && acc[obj.versionStd] > obj.value) {
            acc[obj.versionStd] = obj.value;
        } else {
            acc[obj.versionStd] = obj.value;
        }
        return acc;
    }, {});
    return calculMethod(Object.values(groupByVersion));
}

const processCustomerData = (data, attribute = "month") => {
    const result = {};
    //@ts-ignore
    const custIds = [...new Set(data.map((item) => item.custId))];
    const allMeasures = {
        "List Price": 1,
        Deposit: 2,
        "Web Price": 3,
        Maintenance: 4,
        Tyres: 6,
        Breakdown: 8,
        Replacements: 10,
        Insurance: 12,
        "Financial Loss": 14,
        "Winter Tyres": 16,
        "EV Bonus": 18,
        "CO2 Malus": 20,
    };

    custIds.forEach((custId) => {
        const customerData = data.filter((item) => item.custId === custId);

        result[custId] = {};

        Object.entries(allMeasures).forEach(([measure, index]) => {
            const measureData = customerData[index].data.map((item) => item.value);
            const monthData = customerData[index].data.map((item) => item[attribute]);
            const changes = [];

            for (let i = 1; i < measureData.length; i++) {
                if (measureData[i] !== measureData[i - 1] && measureData[i] !== null && measureData[i - 1] !== null) {
                    changes.push({
                        [attribute]: monthData[i],
                        //Number(measureData[i] - measureData[i - 1]).toFixed(2),
                        value: Math.round(measureData[i] - measureData[i - 1]),
                    });
                }
            }

            if (changes.length > 0) {
                result[custId][measure] = changes;
            }
        });

        if (Object.keys(result[custId]).length === 0) {
            delete result[custId];
        }
    });

    return result;
};

function processTrendDataChanges(trendResult, groupBy = "custId", getCustlabelByCustId, seriesBy) {
    const allMeasures = {
        "List Price": 1,
        Deposit: 2,
        "Web Price": 3,
        Maintenance: 4,
        Tyres: 6,
        Breakdown: 8,
        Replacements: 10,
        Insurance: 12,
        "Financial Loss": 14,
        "Winter Tyres": 16,
        "EV Bonus": 18,
        "CO2 Malus": 20,
    };

    const result = {};
    const changesByMonth = {};

    Object.entries(allMeasures).forEach(([measureName, measureIndex]) => {
        const series = trendResult?.data().series().firstForMeasure(seriesBy[measureIndex]).dataPoints();
        if (series) {
            series.forEach((dataPoint) => {
                const sliceTitles = dataPoint.sliceDesc.sliceTitles();
                const groupKey = groupBy === "custId" ? getCustlabelByCustId(sliceTitles[0]) : sliceTitles[2];
                const secondaryGroupKey = groupBy === "custId" ? sliceTitles[2] : getCustlabelByCustId(sliceTitles[0]);
                const monthCode = sliceTitles[1];
                const value = Number(dataPoint.rawValue) === 0 ? null : Math.round(Number(dataPoint.rawValue));

                if (!result[groupKey]) {
                    result[groupKey] = {};
                }
                if (!result[groupKey][secondaryGroupKey]) {
                    result[groupKey][secondaryGroupKey] = {};
                }
                if (!result[groupKey][secondaryGroupKey][measureName]) {
                    result[groupKey][secondaryGroupKey][measureName] = [];
                }
                result[groupKey][secondaryGroupKey][measureName].push({ month: monthCode, value });
            });
        }
    });

    // Calculate changes and organize by month
    Object.keys(result).forEach((groupKey) => {
        Object.keys(result[groupKey]).forEach((secondaryGroupKey) => {
            Object.keys(result[groupKey][secondaryGroupKey]).forEach((measure) => {
                const data = result[groupKey][secondaryGroupKey][measure];
                for (let i = 1; i < data.length; i++) {
                    if (data[i].value !== data[i - 1].value && data[i].value !== null && data[i - 1].value !== null) {
                        const change = {
                            month: data[i].month,
                            value: Math.round(data[i].value - data[i - 1].value),
                        };
                        if (!changesByMonth[groupKey]) changesByMonth[groupKey] = {};
                        if (!changesByMonth[groupKey][data[i].month]) changesByMonth[groupKey][data[i].month] = {};
                        if (!changesByMonth[groupKey][data[i].month][secondaryGroupKey]) changesByMonth[groupKey][data[i].month][secondaryGroupKey] = {};
                        changesByMonth[groupKey][data[i].month][secondaryGroupKey][measure] = change.value;
                    }
                }
            });
        });
    });

    return changesByMonth;
}

const calculateAdjustment = (slice, n, pref, IncServices) => {
    return IncServices ? Math.round((pref - Number(slice.dataPoints()[n + 1].rawValue)) * Number(slice.dataPoints()[n].rawValue)) : 0;
};

export { mode, toPivot, calculateMedian, calculateAvg, weigthedAverage, weightedAverageByMake, calculateModelAvg, processCustomerData, processTrendDataChanges, calculateAdjustment, medianByMake };
