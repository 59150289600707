// @ts-nocheck
import React, { useState, useEffect, useCallback } from "react";
import { Modal, Select, Button, Table, Typography, message } from "antd";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../../../md/full";
import { mode, weigthedAverage } from "../../../utils/calculationUtils";
import { useBasketContext } from "../../../contexts/BasketProvider";
import { useMutation } from "@apollo/client";
import { GET_BASKETS } from "../../../contexts/BasketProvider";
import { gql } from "@apollo/client";
import { LoadingOutlined } from "@ant-design/icons";

// Create a dedicated mutation that only handles filter updates
const REPLACE_BASKET_VERSION_MUTATION = gql`
    mutation replaceBasketVersion($basketId: uuid!, $filters: [re_rentaleye_basket_filters_insert_input!]!) {
        delete_re_rentaleye_basket_filters(where: { basket_id: { _eq: $basketId } }) {
            returning {
                basket_id
            }
        }
        insert_re_rentaleye_basket_filters(objects: $filters) {
            returning {
                id
            }
        }
    }
`;

const mapResultToVersions = (slices, kpi) => {
    return slices.map((slice) => {
        const dataPoints = slice.dataPoints();
        return {
            Version: slice.sliceTitles()[0],
            CustId: slice.sliceTitles()[1],
            "List Price": Math.round(Number(slice.dataPoints()[1].rawValue)) > 0 ? Math.round(Number(slice.dataPoints()[1].rawValue)) : null,
            Model: slice.sliceTitles()[2],
            Value: dataPoints[kpi?.index]?.rawValue !== null ? (kpi?.index === 24 ? Math.round(10000 * Number(dataPoints[kpi?.index]?.rawValue)) / 100 : Math.round(Number(dataPoints[kpi?.index]?.rawValue))) : null,
            Segment: slice.sliceTitles()[3],
            "Body Style": slice.sliceTitles()[4],
            Gear: slice.sliceTitles()[5],
            Energy: slice.sliceTitles()[6],
            Make: slice.sliceTitles()[7],
            Doors: slice.dataPoints()[35].rawValue,
            Kw: slice.dataPoints()[29].rawValue,
            Power: slice.dataPoints()[22].rawValue,
            CO2: slice.dataPoints()[34].rawValue,
            "Adj. Deposit": Number(slice.dataPoints()[2].rawValue).toFixed(0),
        };
    });
};

const MapToVersionSelection = (data, kpi) => {
    if (data.length > 0) {
        // @ts-ignore
        const custIdKeys = [...new Set(data.map(({ CustId }) => CustId))];
        let vehicleOffersObj = data.reduce((acc, nextItem) => {
            const vehicleInfo = Object.entries(nextItem)
                .filter(([key, val]) => !["CustId", "Value", "List Price"].includes(key))
                .reduce((acc, [key, value]) => Object.assign({ [key]: value, ...acc }), {});
            if (nextItem.Version in acc && nextItem.CustId in acc[nextItem.Version]) {
                acc[nextItem.Version][nextItem.CustId].values.push(nextItem.Value);
                acc[nextItem.Version][nextItem.CustId].versionCount.push(nextItem.versionCount);
            } else {
                acc[nextItem.Version] = {
                    [nextItem.CustId]: {
                        values: [nextItem.Value],
                        versionCount: [nextItem.versionCount],
                    },
                    ...vehicleInfo,
                    ...acc[nextItem.Version],
                };
            }
            acc[nextItem.Version] = {
                ...acc[nextItem.Version],
                listPrices: "listPrices" in acc[nextItem.Version] ? [...acc[nextItem.Version]["listPrices"], nextItem["List Price"]] : [nextItem["List Price"]],
            };
            return acc;
        }, {});
        // Calculate the weighted average
        Object.keys(vehicleOffersObj).forEach((key) => {
            custIdKeys.forEach((custId) => {
                vehicleOffersObj[key][custId] = custId in vehicleOffersObj[key] ? kpi?.roundValue(weigthedAverage(vehicleOffersObj[key][custId]?.values, vehicleOffersObj[key][custId]?.versionCount)) : null;
            });
        });
        const dataSource = Object.values(vehicleOffersObj).map((item) => {
            const custValues = Object.keys(item)
                .filter((key) => custIdKeys.includes(key) && item[key] > 0)
                .map((key) => item[key]);
            let rentalSum = custValues.reduce((acc, currentVal) => acc + currentVal, 0);
            let Average = rentalSum > 0 ? kpi?.roundValue(rentalSum / custValues.length) : null;
            let ModListPrice = item.listPrices?.length > 0 ? Math.round(Number(mode(item.listPrices))) || null : null;

            return { Version: item.Version, "List Price": ModListPrice, Average, ...item };
        });
        return dataSource;
    }
};

const BasketVersionMatch = ({ Vehicle, onCancel, seriesBy, slicesBy, kpi, filters, custSlots, basket }) => {
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [filteredVersions, setFilteredVersions] = useState([]);
    const [matchVersions, setMatchVersions] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selection, setSelection] = useState([]);
    const [models, setModels] = useState([]);
    const [selectOpen, setSelectOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState([]);
    const [replaceBasketVersion] = useMutation(REPLACE_BASKET_VERSION_MUTATION);
    const { editBasket } = useBasketContext();

    const onReplaceVehicle = useCallback(
        (SelectedVehicle) => {
            setLoading(true);
            // Get all filters except the one we're replacing
            let updatedFilters = basket.basketFilters
                .filter(({ value, type }) => value !== Vehicle.value && type === "version_std")
                .map((filter) =>
                    Object.assign({
                        type: "version_std",
                        value: filter.value,
                        basket_id: basket.id,
                        make: filter.make,
                        model: filter.model,
                        energy: filter.energy,
                        veh_type: filter.veh_type,
                        doors: filter.doors,
                        body: filter.body,
                        gear: filter.gear,
                        power: filter.power,
                    }),
                );

            // Add the replacement vehicle
            updatedFilters.push({
                type: "version_std",
                value: SelectedVehicle.Version,
                basket_id: basket.id,
                make: SelectedVehicle.Make,
                model: SelectedVehicle.Model,
                energy: SelectedVehicle.Energy,
                list_price: SelectedVehicle["List Price"],
                veh_type: SelectedVehicle.VehType,
                doors: SelectedVehicle.Doors,
                body: SelectedVehicle["Body Style"],
                gear: SelectedVehicle.Gear,
                power: SelectedVehicle.Power,
            });

            replaceBasketVersion({
                variables: {
                    basketId: basket.id,
                    filters: updatedFilters,
                },
                refetchQueries: [{ query: GET_BASKETS }],
            })
                .then(() => {
                    editBasket(basket.id, {});
                    message.success({
                        content: "Vehicle replaced in the basket",
                        style: {
                            top: "80px",
                            right: "20px",
                            position: "absolute",
                        },
                        duration: 2,
                    });
                })
                .catch((error) => {
                    if (error.name === "ApolloError") {
                        message.error({
                            content: "Network error: Unable to replace vehicle",
                            style: {
                                top: "80px",
                                right: "20px",
                                position: "absolute",
                            },
                            duration: 2,
                        });
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [basket.basketFilters, basket.id, Vehicle.value, editBasket, replaceBasketVersion],
    );

    // First query
    const {
        status: statusFirstQuery,
        result: resultFirstQuery,
        error: errorFirstQuery,
    } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: slicesBy,
            filters: [
                ...filters,
                newPositiveAttributeFilter(Md.CustId, custSlots),
                newPositiveAttributeFilter(Md.VehType, [Vehicle?.veh_type]),
                newPositiveAttributeFilter(Md.CorrMake, [Vehicle?.make]),
                newPositiveAttributeFilter(Md.CorrModel, [Vehicle?.model]),
                newPositiveAttributeFilter(Md.Fuel, [Vehicle?.energy]),
                newPositiveAttributeFilter(Md.BodyStyle, [Vehicle?.body]),
                newPositiveAttributeFilter(Md.GearsPlus, [Vehicle?.gear]),
            ],
        },
    });

    useEffect(() => {
        if (statusFirstQuery === "success") {
            const slices = resultFirstQuery?.data()?.slices()?.toArray();
            if (slices.length > 0) {
                setFilteredVersions(mapResultToVersions(slices, kpi));
            }
        } else if (statusFirstQuery === "error") {
            setCurrentStep(2);
        }
    }, [statusFirstQuery, errorFirstQuery]);

    // fetch models based on selected brands
    const {
        status: statusModels,
        result: resultModels,
        error: errorModels,
    } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CorrModel],
            filters: [...filters, newPositiveAttributeFilter(Md.CorrMake, [Vehicle?.make])],
        },
    });

    useEffect(() => {
        if (statusModels === "success") {
            setModels(
                resultModels
                    .data()
                    .slices()
                    .toArray()
                    .map((item) => item.sliceTitles()[0]),
            );
        }
    }, [statusModels, resultModels, errorModels]);

    // Final query
    const { status: statusFinalQuery, result: resultFinalQuery } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: slicesBy,
            filters: [...filters, newPositiveAttributeFilter(Md.CustId, custSlots), newPositiveAttributeFilter(Md.CorrModel, selectedModel)],
        },
    });

    useEffect(() => {
        if (statusFinalQuery === "success" && selectedModel.length > 0 && currentStep > 2) {
            const slices = resultFinalQuery.data().slices().toArray();
            if (slices.length !== 0) {
                setFilteredVersions(mapResultToVersions(slices, kpi));
            }
        }
    }, [statusFinalQuery, selectedModel, currentStep]);

    useEffect(() => {
        let resultedata = [];
        if (filteredVersions.length !== 0 && Vehicle && [1, 3].includes(currentStep)) {
            // Filter versions based on power
            const filteredByPower = filteredVersions.filter((version) => {
                const power = Number(version.Power);
                const selectedPower = Number(Vehicle?.power);
                const powerThreshold = selectedPower * 0.3;
                return power >= selectedPower - powerThreshold && power <= selectedPower + powerThreshold;
            });

            if (filteredByPower.length > 0) {
                resultedata = MapToVersionSelection(filteredByPower, kpi);
            } else {
                resultedata = MapToVersionSelection(filteredVersions, kpi);
            }
            if (resultedata?.length > 0) {
                setMatchVersions([...resultedata]);
                setCurrentStep(3);
            }
        }
    }, [filteredVersions, Vehicle, kpi]);

    useEffect(() => {
        if (matchVersions.length > 0) {
            const columns = ["Version", "List Price", "Power", "Power Gap", "Body Style", "Gear", "Doors", "CO2"];
            setColumns(
                columns.map((m) => {
                    return {
                        title: () => <div>{["Version", "List Price", "Power"].includes(m) ? <span className="text-xs">{m}</span> : <span className="flex-1 text-xs self-center">{m}</span>}</div>,
                        fixed: ["Version"].includes(m) ? "left" : null,
                        align: m !== "Version" && "center",
                        dataIndex: m,
                        key: m,
                        width: m === "Version" ? 320 : ["List Price", "Power"].includes(m) ? 120 : m.length > 8 ? m.length * 10 : 100,
                        render: (cell, record) => {
                            let cellContent = cell;
                            if (m === "Power Gap" && record.Power !== null) {
                                const diffPercent = Math.round((Number(record.Power) / Number(Vehicle.power) - 1) * 100);
                                cellContent = `${diffPercent} %`;
                            }
                            return (
                                <Typography.Text
                                    style={{
                                        fontSize: 10,
                                        padding: "2px",
                                        borderRadius: "2px",
                                    }}
                                >
                                    {cellContent?.toLocaleString("en-US", { useGrouping: false })}
                                </Typography.Text>
                            );
                        },
                        onFilter: (value, record) => (["Version", "List Price", "Average"].includes(m) ? value : record[m] !== value),
                    };
                }),
            );
        }
    }, [matchVersions, Vehicle, currentStep]);

    const handleModelSelection = (value) => {
        setSelectedModel(value);
        setSelectOpen(false);
    };

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };
    const resetState = () => {
        setColumns([]);
        setCurrentStep(1);
        setFilteredVersions([]);
        setMatchVersions([]);
        setSelection([]);
        setModels([]);
        setSelectedModel([]);
    };

    const handleCancel = () => {
        resetState();
        onCancel();
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div className="text-center">
                        <span className="text-indigo-700 text-base font-medium">Searching for alternative matches ...</span>
                        <LoadingComponent />
                    </div>
                );
            case 2:
                return (
                    <div>
                        <h2>Alternative version not found</h2>
                        <p className="text-secondary-300">Please select a set of models to find a replacement:</p>
                        <Select style={{ width: "100%" }} allowClear mode="multiple" placeholder="Select model" onChange={handleModelSelection} loading={models.length === 0} open={selectOpen} onDropdownVisibleChange={setSelectOpen}>
                            {models.map((model) => (
                                <Select.Option key={model} value={model}>
                                    {model}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <p className="text-indigo-700 text-base font-medium">Vehicle to replace : {Vehicle?.value}</p>
                        <style jsx>{`
                            .selected-row td {
                                background-color: #3a0ca3 !important;
                            }
                            .ant-table-row-disabled > td {
                                cursor: not-allowed;
                            }
                            :global(.ant-table-tbody > tr > td) {
                                padding: 3px 6px !important;
                                line-height: 1 !important;
                            }
                        `}</style>
                        <Table
                            size="small"
                            dataSource={matchVersions}
                            columns={columns}
                            scroll={{ x: 800, y: 300 }}
                            rowKey={(record) => record.Version}
                            pagination={false}
                            rowSelection={{
                                type: "radio",
                                onSelect: (record, selected, selectedRows, nativeEvent) => {
                                    setSelection([...selectedRows.filter((row) => row?.Version)]);
                                },
                                selectedRowKeys: selection.map((item) => item.Version),
                            }}
                            loading={matchVersions.length === 0}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            title="Alternatives version"
            width={"100%"}
            open={true}
            onCancel={handleCancel}
            footer={[
                <Button
                    key="next"
                    type="primary"
                    onClick={
                        currentStep === 3
                            ? () => {
                                  onReplaceVehicle(selection[0]);
                                  handleCancel();
                              }
                            : () => {
                                  nextStep();
                              }
                    }
                    disabled={currentStep === 1 || (currentStep === 3 && selection.length === 0) || (currentStep === 2 && selectedModel.length === 0) || loading}
                >
                    {loading ? <LoadingOutlined /> : currentStep === 3 ? "Replace" : "Next"}
                </Button>,
            ]}
        >
            {renderStep()}
        </Modal>
    );
};

export default BasketVersionMatch;
